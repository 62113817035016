import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import Player from '@vimeo/player';
import fitvids from 'fitvids';
import { isPlatformBrowser } from '@angular/common';
import { MutationService } from '../../../Library/services/mutation.service';
import { PlatformService } from '../../../@modules/platform/services/platform.service';

@Component({
  selector: 'app-video-module',
  templateUrl: './video-module.component.html',
  styleUrls: ['./video-module.component.scss']
})
export class VideoModuleComponent implements AfterViewInit, OnDestroy {
  @Input() video_id: number;

  @ViewChild('videoModule') videoModule: ElementRef;
  @ViewChild('playButton') playButton: ElementRef;

  player: Player;

  fullscreenInitialised: boolean = false;
  fullscreen: boolean = false;

  container_id: string = 'video-module-' + Math.floor(Math.random() * 1000);

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private mutationService: MutationService,
    private platformService: PlatformService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.mutationService.domChange.subscribe((dom: HTMLElement) => {
      const containerElem: HTMLElement = dom.querySelector('#' + this.container_id)

      if (!containerElem) {
        return;
      }

      this.player = new Player(containerElem, {
        id: this.video_id,
        width: 1920,
        height: 1080,
        controls: !this.platformService.isMobile(),
        muted: true,
        loop: true,
        autoplay: true,
        autopause: true,
      })

      this.player.on('loaded', () => {
        fitvids();
      });
    })

    this.attachPlayButtonToCursor();
  }

  attachPlayButtonToCursor(): void {
    const videoModuleElem = this.videoModule.nativeElement;

    videoModuleElem.addEventListener('mouseover', this.relocateButton);
    videoModuleElem.addEventListener('mousemove', this.relocateButton);
  }

  relocateButton = (event: MouseEvent): void => {
    const playButtonElem = this.playButton.nativeElement;

    const yPosition: number = event.pageY - playButtonElem.clientHeight / 2;
    const xPosition: number = event.pageX - playButtonElem.clientWidth / 2;

    window.requestAnimationFrame((): void => {
      playButtonElem.style.top = yPosition.toString() + 'px';
      playButtonElem.style.left = xPosition.toString() + 'px';
    });
  };

  openFullscreen(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.player.requestFullscreen().then(() => {
      if (!this.fullscreenInitialised) {
        this.fullscreen = true;
        this.player.setMuted(false);
        this.player.play();
        this.player.setCurrentTime(0);
        this.changeDetectorRef.detectChanges();

        this.fullscreenInitialised = true;
      }

      this.player.on('fullscreenchange', (event) => {
        this.fullscreen = event.fullscreen;
        this.player.setMuted(!this.fullscreen);
        this.player.play();
        this.changeDetectorRef.detectChanges();
      })
    })
  }

  ngOnDestroy(): void {
    this.player?.destroy();
  }
}
