import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MutationService } from './mutation.service';
import { PlatformService } from '../../@modules/platform/services/platform.service';

@Injectable({
  providedIn: 'root'
})
export class ParallaxService {
  private intersectionObserver: IntersectionObserver;
  rootMargin: string = '100px';

  private parallaxWrapperElement: HTMLElement;
  private parallaxElements: NodeListOf<HTMLElement>;

  constructor(
    private platformService: PlatformService,
    private mutationService: MutationService,
  ) {}

  public init(): void {
    if (!this.platformService.isBrowser()) {
      return;
    }

    this.mutationService.domChange.subscribe((dom) => {
      this.createParallaxEffect(dom);
    });
  }

  public createParallaxEffect(dom: HTMLElement = document.documentElement): void {
    if (!this.platformService.isBrowser()) {
      return;
    }

    this.parallaxWrapperElement = dom.querySelector('.parallax-wrapper');

    if (!this.parallaxWrapperElement) {
      return;
    }

    this.setElementProperty(this.parallaxWrapperElement, '--root-margin', this.rootMargin);

    this.parallaxElements = dom.querySelectorAll('.parallax-element');

    this.createIntersectionObserver();

    this.createFadeoutEffect();
  }

  private createIntersectionObserver(): void {
    if (this.intersectionObserver) {
      delete this.intersectionObserver;
    }

    const options = {
      root: null,
      rootMargin: '-' + this.rootMargin,
      threshold: 0
    };

    this.intersectionObserver = new IntersectionObserver(this.handleIntersect, options);

    this.parallaxElements.forEach((element: HTMLElement) => {
      this.intersectionObserver.observe(element);
    });
  }

  private handleIntersect(entries): void {
    entries.forEach((entry) => {
      const previousSibling = entry.target.previousElementSibling;
      if (!previousSibling) {
        return;
      }

      if (entry.isIntersecting) {
        if (previousSibling.classList.contains('parallax-element')) {
          previousSibling.classList.add('parallax-background-element');
        }
      } else {
        previousSibling.classList.remove('parallax-background-element');
      }
    });
  }

  private createFadeoutEffect(): void {
    if (!this.parallaxWrapperElement) {
      return;
    }

    this.parallaxWrapperElement.addEventListener('scroll', () => {
      this.parallaxElements.forEach((element: HTMLElement) => {
        if (element.classList.contains('parallax-background-element')) {
          this.setFadeoutOpacity(element);
        }
      });
    });
  }

  private setFadeoutOpacity(element: HTMLElement): void {
    const rootMarginValue: number = parseInt(this.rootMargin.split('px')[0], 10)
    const rect: DOMRect = element.getBoundingClientRect();
    const fadeoutOpacity: number = (window.innerHeight - rect.bottom - rootMarginValue) / window.innerHeight;

    if (fadeoutOpacity > 0 && fadeoutOpacity < 1) {
      this.setElementProperty(element, '--fadeout-opacity',fadeoutOpacity.toString());
    }
  }

  private setElementProperty(element: HTMLElement, property: string, value: string) {
    element.style.setProperty(property, value);
  }

  public destroy(): void {
    if (this.platformService.isBrowser()) {
      delete this.intersectionObserver;
      delete this.parallaxWrapperElement;
      delete this.parallaxElements;
    }
  }

}
