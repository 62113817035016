import { Component } from '@angular/core';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { PlatformService } from '../../../platform/services/platform.service';
import { TypeformService } from '../../../../Library/services/typeform.service';

@Component({
  selector: 'app-contact-hero',
  templateUrl: './contact-hero.component.html',
})
export class ContactHeroComponent {
  faArrowRight = faArrowRight;

  constructor(
    protected platformService: PlatformService,
    protected typeformService: TypeformService
  ) {
  }
}
