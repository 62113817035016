export class MenuItem {

  public title: string;
  public url: string;
  public external: boolean = false;

  public constructor(data?: {}) {
    Object.assign(this, data);
  }
}
