import { NgOptimizedImage } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeaderComponent } from './@shared/components/header/header.component';
import { AppLayoutComponent } from './@shared/layouts/app-layout/app-layout.component';
import { SharedModule } from './@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomReuseStrategy } from './Library/custom-reuse-strategy';
import { FormComponent } from './Library/form/form.component';
import { LocaleInterceptor } from './Library/interceptors/locale.interceptor';
import { environment } from '../environments/environment';
import { BaseComponent } from './Library/compontents/base.component';
import { UUIDInterceptor } from './Library/interceptors/uuid.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DesignSystemModule } from './@modules/design-system/design-system.module';
import { HomepageModule } from './@modules/homepage/homepage.module';
import { ContactModule } from "./@modules/contact/contact.module";
import { AboutModule } from "./@modules/about/about.module";
import { WorkModule } from "./@modules/work/work.module";
import { AnimatedUnderlineComponent } from './@shared/components/animated-underline/animated-underline.component';
import { NotFoundModule } from './@modules/not-found/not-found.module';
import { MenuItemComponent } from './@shared/components/menu-item/menu-item.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AppLayoutComponent,
    BaseComponent,
    FormComponent,
  ],
    imports: [
        BrowserModule.withServerTransition({appId: 'fleka-website'}),
        TransferHttpCacheModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        DesignSystemModule,
        FontAwesomeModule,
        HttpClientModule,
        SharedModule,
        ServiceWorkerModule.register('ngsw-worker.js'),
        HomepageModule,
        ContactModule,
        AboutModule,
        WorkModule,
        NgOptimizedImage,
        AnimatedUnderlineComponent,
        NotFoundModule,
        MenuItemComponent
    ],
  providers: [
    {
      provide: APP_ID,
      useValue: 'base',
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LocaleInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UUIDInterceptor,
      multi: true,
    },
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({enabled: environment.production || environment.staging}),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
