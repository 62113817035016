import { Component, Input } from '@angular/core';
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import { ProjectService } from '../../../project/services/project.service';
import { Project } from '../../../project/models/project.model';

@Component({
  selector: 'app-work-project-card',
  templateUrl: './work-project-card.component.html'
})
export class WorkProjectCardComponent {

  @Input() project: Project;

  faArrowUpRight = faArrowUpRight;

  constructor(
    protected projectService: ProjectService) {
  }
}
