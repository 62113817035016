<div class="group w-full flex flex-col items-start pb-11 sm:pb-0 border-b sm:border-b-0 border-gray-300 hover:cursor-pointer"
     (mouseenter)="projectService.startVideo($event)"
     (mouseleave)="projectService.pauseVideo($event)"
     tabindex="0">
  <div class="w-full aspect-3/2 sm:aspect-square rounded-3 sm:rounded-4 overflow-hidden">
    <app-project-graphics [project]="project"></app-project-graphics>
  </div>
  <div class="w-full pt-6 sm:pt-scale-h-11 sm:px-scale-w-4">
    <div class="w-20/24 flex flex-col gap-2 sm:gap-scale-h-5">
      <div class="flex items-center gap-2 sm:gap-scale-w-3">
        <fa-icon [icon]="faArrowUpRight"
                 class="block w-3 sm:w-scale-w-4 sm:min-w-[14px] aspect-square leading-100"></fa-icon>
        <span class="text-16 text-scale-20 font-500 uppercase
        group-focus:animate-pulse group-active:animate-none">
          Case study coming soon
        </span>
      </div>
      <h2 class="text-26 text-scale-40 leading-122 text-black font-800">
        {{ project.title }}
      </h2>
    </div>
  </div>
</div>
