const onLightPath: string = 'assets/img/logos/brand_logos/on_light/';
const onDarkPath: string = 'assets/img/logos/brand_logos/on_dark/';

export class BrandLogo {
  onLight: string;
  onDark: string;
  alt: string;

  constructor(filename: string) {
    this.onLight = onLightPath + filename;
    this.onDark = onDarkPath + filename;
    this.alt = filename.split('.')[0];
  }
}

const brandLogos: string[] = [
    'otp-banka.svg',
    'hipotekarna-banka.svg',
    'lovcen-banka.svg',
    'nlb-banka.svg',
    'erste-bank.svg',
    'ckb-banka.svg',
    'european-bank.svg',
    'government-of-montenegro.svg',
    'british-embassy-podgorica.svg',
    'usa-embassy.svg',
    'undp.svg',
    'unicef.svg',
    'prada.svg',
    'nike.svg',
    'coca-cola.svg',
    'bmw.svg',
    'hard-rock-cafe.svg',
    'wired.svg',
    'angelo-po.svg',
    'telenor.svg',
    'porto-montenegro.svg',
    'lustica-bay.svg',
    'portonovi.svg',
    'podravka.svg',
    'niksicko-pivo.svg',
    'jelen-pivo.svg',
    'montenegro-wild-beauty.svg',
    'nezavisni-dnevnik-vijesti.svg',
    'analitika.svg',
    'pobjeda.svg',
    'lovcen-osiguranje.svg',
    'maxima.svg',
    'cosmetics.svg',
    'delta-city.svg',
    'stadion.svg',
    'ict-hub.svg',
    'tea-medica.svg',
    'ijzcg.svg',
    'zetski-dom.svg',
    'muzicki-centar-crne-gore.svg',
    'zain-property-development.svg',
    'bbdo.svg',
    'privredna-komora-crne-gore.svg',
    'deltacar-montenegro.svg',
    'paradiso.svg',
    'gradska-cetinje.svg',
    'dream-estates-montenegro.svg',
    'doktok.svg',
]

export const BRAND_LOGOS : BrandLogo[] = brandLogos.map((filename: string) => new BrandLogo(filename))
