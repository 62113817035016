import { Component } from '@angular/core';
import { PlatformService } from '../../../platform/services/platform.service';
import { faXmark } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-about-employee-list',
  templateUrl: './about-employee-list.component.html',
})
export class AboutEmployeeListComponent {

  employees: { image: string; name: string; position: string }[] = [
    {
      name: 'Miloš Milošević',
      position: 'CEO',
      image: 'assets/img/team/milos-milosevic.jpg'
    },
    {
      name: 'Marko Lekić',
      position: 'CTO',
      image: 'assets/img/team/marko-lekic.jpg'
    },
    {
      name: 'Miodrag Račić',
      position: 'Project Manager',
      image: 'assets/img/team/miodrag-racic.jpg'
    },
    {
      name: 'Dragana Vučić',
      position: 'Project Manager',
      image: 'assets/img/team/dragana-vucic.jpg'
    },
    {
      name: 'Milica Stanković',
      position: 'Customer Experience Manager',
      image: 'assets/img/team/milica-stankovic.jpg'
    },
    {
      name: 'Damir Ljaljević',
      position: 'AML Officer',
      image: 'assets/img/team/damir-ljaljevic.jpg'
    },
    {
      name: 'Marko Lalić',
      position: 'Lead Designer',
      image: 'assets/img/team/marko-lalic.jpg'
    },
    {
      name: 'Vladimir Borović',
      position: 'Designer',
      image: 'assets/img/team/vladimir-borovic.jpg'
    },
    {
      name: 'Ilija Roganović',
      position: 'Designer',
      image: 'assets/img/team/ilija-roganovic.jpg'
    },
    {
      name: 'Srđan Tomović',
      position: 'Lead Developer',
      image: 'assets/img/team/srdjan-tomovic.jpg'
    },
    {
      name: 'Ivana Marković',
      position: 'Frontend Developer',
      image: 'assets/img/team/ivana-markovic.jpg'
    },
    {
      name: 'Nemanja Novović',
      position: 'DevOps Engineer',
      image: 'assets/img/team/nemanja-novovic.jpg'
    },
    {
      name: 'Rade Veljić',
      position: 'Frontend Developer',
      image: 'assets/img/team/rade-veljic.jpg'
    },
    {
      name: 'Matija Šuković',
      position: 'Frontend Developer',
      image: 'assets/img/team/matija-sukovic.jpg'
    },
    {
      name: 'Petar Tomović',
      position: 'Mobile Developer',
      image: 'assets/img/team/petar-tomovic.jpg'
    },
    {
      name: 'Jovana Stamatović',
      position: 'Financial and administrative Assistant',
      image: 'assets/img/team/jovana-stamatovic.jpg'
    },
  ];

  currentImage: string =  '';

  imageLoaded: boolean = false;

  faXmark = faXmark;

  constructor(protected platformService: PlatformService) {
  }

  updateImage(i: number): void {
    this.imageLoaded = true;
    this.currentImage = this.employees[i].image;
  }

  closeImage(): void {
    this.imageLoaded = false;
    this.currentImage = '';
  }
}
