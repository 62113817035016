<div class="w-full rounded-3 sm:rounded-12 bg-gray-300 aspect-3/2 sm:aspect-video relative overflow-hidden">

  <picture *ngIf="contentModule.imageDesktop && contentModule.imageMobile">
    <source media="(min-width:768px)" [srcset]="contentModule.imageDesktop">
    <source media="(max-width:768px)" [srcset]="contentModule.imageMobile">
    <img [src]="contentModule.imageDesktop" alt="Fleka"
         class="absolute inset-0 object-cover object-center w-full h-full">
  </picture>

</div>
