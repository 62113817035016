import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MediaRoutingModule } from './media-routing.module';
import { RouterModule } from '@angular/router';
import { MediaService } from './services/media.service';
import { ItemResolver } from './media-routing.module';
import { SingleMediaUploadComponent } from './components/single-media-upload/single-media-upload.component';
import { DndDirective } from './directives/dnd.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    SingleMediaUploadComponent,
    DndDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MediaRoutingModule,
    RouterModule,
    FontAwesomeModule,
  ],
  providers: [
    MediaService,
    ItemResolver,
  ],
  exports: [
    SingleMediaUploadComponent,
  ],
})
export class MediaModule {
}
