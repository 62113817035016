import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  startVideo(event): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const element = event.target;
    const videoElement: HTMLVideoElement = element.querySelector('video');

    videoElement.muted = true;
    videoElement.play();
  }

  stopVideo(event): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const element: HTMLElement = event.target;
    const videoElement: HTMLVideoElement = element.querySelector('video');

    setTimeout(() => {
      videoElement.pause();
      videoElement.currentTime = 0;
    },200)
  }

  pauseVideo(event): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const element: HTMLElement = event.target;
    const videoElement: HTMLVideoElement = element.querySelector('video');

    videoElement.pause();
  }
}
