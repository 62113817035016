import { Component } from '@angular/core';

@Component({
  selector: 'app-cosmetics',
  templateUrl: './cosmetics.component.html',
  styleUrls: ['./cosmetics.component.scss']
})
export class CosmeticsComponent {

  contentModules: any = [
    {
      title: 'Digitization of the largest loyalty program in our country ',
      imageDesktop: 'https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis01Desktop.jpg',
      imageMobile: 'https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis01Desktop.jpg',
      videoSrc: 'https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/video/cosmetics-hero-video.mp4',
      description: `Cosmetics spent two decades earning a devoted following, all without any advertising. The Montenegro-based
              company was worth 50 million euros, but they still hadn’t translated their ethos of “unselling” online.
              They asked Fleka to design, build, and ship a new e-commerce site and native applications. <br><br>
              Working together, we created an immersive digital storefront inspired by the in-store experience.
              Our relationship grew into broad customer-experience strategy, including in-store and sales associate
              touchpoints, content integration, and a new loyalty program.`
    },
    {
      order: 1,
      title: 'Program lojalnosti',
      description: `Ako znamo da brojke govore više od riječi, jasno je koliko je odgovoran i izazovan zadatak bio kreirati
      potpuno novo korisničko iskustvo za više od 140.000 članova Beauty Friend programa lojalnosti. <br><br>
        Svaki kupac želi da ostvari popust i dobije više za svoj novac. Ali, previše plastike u novčaniku mnoge odbija
      od pristupanja nekom od mnoštva dostupnih programa lojalnosti na našem tržištu. Sa tom mišlju i puno pasije,
      ušli smo u projekat digitalizacije jedne od najpoznatijih loyalty kartica, koja na crnogorskom tržištu postoji
      više od 10 godina - Beauty Friend kartice.`
    },
    {
      imageDesktop: 'https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis02Desktop.jpg',
      imageMobile: 'https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/mobile/cosmetis02Mobile.jpg'
    },
    {
      order: 2,
      title: 'Proces registracije korisnika',
      description: `Umjesto da počnemo sa dizajnom početne stranice, ili stranice proizvoda, razmotrili smo šta je najbitnije u
          startu da se obezbijedi korisnicima, a ujedno da je važno za brend. Sa tom mišlju, pristupili smo rješavanju
          problema prebacivanja, odnosno digitalizaciji postojećih BF korisnika.`
    },
    {
      title: `Korisničko iskustvo`,
      firstDescription: `Kako osmisliti jednostavan onboarding za postojeće korisnike, bez papirologije, u potpuno digitalnom okruženju?
          Za ovako veliku seriju kartica osmislili smo potpuno novi digitalni koncept, Cosmetics mobilnu aplikaciju,
          putem koje je moguće priključliti se programu lojalnosti, digitalizovati postojeću loyalty karticu,
          sakupljati i pratiti stanje loyalty bodova.`,
      secondDescription: `Proces razvoja dizajna za Cosmetics mobilne aplikacije imao je za cilj da privuče kupce da ovaj proizvod dožive
          kao jedan od najvažnijih alata u komunikaciji sa kompanijom. Korisnici u svakom trenutku na raspolaganju imaju
          pregled aktuelnih promocija, informacije o trendovima, novitetima, nagradnim igrama, pristup šoping listi kao i
          interaktivni pregled korisničkog profila i svih pogodnosti programa lojalnosti.`
    },
    {
      imageDesktop: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis03Desktop.jpg`,
      imageMobile: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/mobile/cosmetis07Mobile.jpg`
    },
    {
      title: 'User Cetered Design',
      description: `Kako danas živimo u smart svijetu, plasiranje velikog broja informacija na tako malom prostoru zahtijevalo je
             smart dizajnersko rješenje. Dizajn koji stavlja korisnika na prvo mjesto (User Centered Design), uz pažljivo
              osmišljeni UX (dizajn korisničkog iskustva) je najvažniji segment ove aplikacije. Njegov značaj se ogleda i
              u tome što predstavlja ključnu razliku u percepciji proizvoda kod korisnika naviknutih na standarde
              svjetskih shopping i loyalty aplikacija, i zato smo mu posvetili posebnu pažnju.`
    },
    {
      firstImageDesktop: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis04Desktop.jpg`,
      firstImageMobile: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/mobile/cosmetis04Mobile.jpg`,
      secondImageDesktop: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis05Desktop.jpg`,
      secondImageMobile: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/mobile/cosmetis05Mobile.jpg`
    },
    {
      order: 3,
      title: 'Katalog proizvoda',
      description: `Konstantna komunikacija sa klijentom i mnoštvo intervjua su nam pomogli da shvatimo koje su navike naših budućih
         korisnika i šta su njihove potrebe i očekivanja od ove aplikacije. Rezultate tih istraživanja smo kasnije
         iskoristili da napravimo strukturu informacija koja je intuitivna i laka za korišćenje.<br><br>
         Na početnoj stranici smo napravili modularan pristup u dizajnu, koji kompaniji omogućava tako da klijent
         može da napravi arhitekturu informacija u skladu sa trenutnom biznis strategijom. Sa korisničke strane, htjeli smo
         da upoznamo korisnike sa dijapazonom kategorija (ljepota i zdravlje) i njihovih proizvoda koji se nude.`
    },
    {
      imageDesktop: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis06Desktop.jpg`,
      imageMobile: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/mobile/cosmetis06Mobile.jpg`
    },
    {
      title: `Kategorizacija proizvoda`,
      description: ` Osim prikaza kompletnog asortimana, u okviru digitalnog kataloga proizvodi su kategorizovani po više
            kriterijuma, a mnogi od njih su dostupni u okviru više različitih promotivnih akcija. Bez obzira na to koliko
            imaju opcija, prikaz proizvoda i pristup informacijama koncipiran je na način da ne odbije korisnika od daljeg
             korišćenja aplikacije. Kreiranju vizuelnog rješenja prethodila je detaljna analiza strukture podataka i
             pozadinskih poslovnih procesa. Katalog je dizajniran na način da omogući lako pronalaženje i pregled
             proizvoda, uz vrhunsko korisničko iskustvo.`,
    },
    {
      imageDesktop: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis07Desktop.jpg`,
      imageMobile: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/mobile/cosmetis07Mobile.jpg`
    },
    {
      order: 4,
      title: 'Proces kupovine proizvoda',
      description: ` Cosmetics aplikacija je intuitivna i jednostavna za korišćenje. Pored toga što predstavlja dodatni marketinški
         alat, osnova je za nadogradnju poslovno interesantnog sadržaja i unapređenje i jačanje Beauty friend programa
         lojalnosti.<br><br>
         Pored dobro struktuiranih stranica za prikaz asortimana, akcenat u dizajnu smo stavili na korisnički put do
          kupovine konketnih proizvoda…`
    },

    {
      firstImageDesktop: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis08Desktop.jpg`,
      firstImageMobile: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/mobile/cosmetis08Mobile.jpg`,
      secondImageDesktop: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis09Desktop.jpg`,
      secondImageMobile: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/mobile/cosmetis09Mobile.jpg`
    },
    {
      title: 'Korpa',
      description: `Svi proizvodi koje je korisnik dodao u korpi su izlistani i grupisani tako da na kraju korisnik ima već
           predefinisanu najpovoljniju opciju za kupovinu grupe proizvoda. <br><br>
           Aplikacija automatski primjenjuje Beauty Friend popuste na zadatu kombinaciju proizvoda. Korisnik neće morati
           da računa koji su to popusti najpovoljniji za njega, njegov zadatak je da ide korak dalje da bi finalizovao kupovinu.`
    },
    {
      title: 'What we did',
      listTitle: 'Highlights:',
      list: [
        'Vision for transformation',
        'Product strategy and roadmapping',
        'Design and art direction',
        'Content strategy',
        'Solution arcitecture',
        'User testing',
        'Training for Cosmetics business developers during handoff'
      ]
    },
    {
      quote: `“It was a highly collaborative process. They took the time to become well-versed in the intricacies of our
      business, and instead of big reveals at set milestones, we were in constant communication throughout the process.
        That enabled us to experiment with a wide variety of ideas and to incorporate findings quickly.”`,
      author: 'Tomislav Đorojević',
      position: 'Founder and CEO at MPM Cosmetics'
    },
    {
      title: 'Montenegrin Music Center',
      description: 'Building a digital-first customer experience',
      imageDesktop: 'https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis10Desktop.jpg',
      imageMobile: 'https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis10Desktop.jpg'
    }
  ];

}
