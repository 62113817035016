import { Component } from '@angular/core';
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import { ProjectService } from '../../../project/services/project.service';
import { Project } from '../../../project/models/project.model';
import { environment } from '../../../../../environments/environment';
import { TypeformService } from '../../../../Library/services/typeform.service';

@Component({
  selector: 'app-homepage-featured-projects',
  templateUrl: './homepage-featured-projects.component.html',
})
export class HomepageFeaturedProjectsComponent {

  projects: Project[] = [
    {
      title: 'Website for the Government of Montenegro',
      video_path_webm: environment.s3Repository + '/videos/projects/gov/gov.webm',
      video_path_mp4: environment.s3Repository + '/videos/projects/gov/gov.mp4',
      thumbnail: environment.s3Repository + '/videos/projects/gov/gov.jpg',
      url: '#',
    },
    {
      title: 'Online banking (Web/App) and website for Lovćen banka',
      video_path_mp4: environment.s3Repository + '/videos/projects/lovcen/lovcen.mp4',
      video_path_webm: environment.s3Repository + '/videos/projects/lovcen/lovcen.webm',
      thumbnail: environment.s3Repository + '/videos/projects/lovcen/lovcen.jpg',
      url: '#'
    },
    {
      title: 'UX/UI design, Product development for web and mobile app for Cosmetics',
      video_path_webm: environment.s3Repository + '/videos/projects/cosmetics/cosmetics.webm',
      video_path_mp4: environment.s3Repository + '/videos/projects/cosmetics/cosmetics.mp4',
      thumbnail: environment.s3Repository + '/videos/projects/cosmetics/cosmetics.jpg',
      url: '#'
    }
  ];

  faArrowUpRight = faArrowUpRight;

  constructor(
    protected projectService: ProjectService,
    protected typeformService: TypeformService
  ) {
  }
}
