<div class="pt-32 sm:pt-scale-h-67 bg-gray-100 parallax-wrapper">

  <div class="container">
    <h1 class="font-abril-bold text-30 text-scale-100 text-black leading-111">
      Cosmetics
    </h1>
    <span class="mt-1 font-300 text-24 text-scale-42 leading-140 ">Mobile app</span>
  </div>

  <div class="container">
    <div class="w-full pt-10 sm:pt-scale-h-18 pb-12 sm:pb-scale-h-26">
      <app-module-a010
        [contentModule]=contentModules[0]
      ></app-module-a010>
    </div>
  </div>

  <div class="w-full h-px bg-gray-300"></div>

  <div class="container">
    <div class="w-full pt-10 pb-12 sm:py-scale-h-27">
      <app-module-a020
        [contentModule]="contentModules[1]"
      ></app-module-a020>
    </div>

    <div class="w-full pt-2 pb-10 sm:py-10">
      <app-module-a050
        [contentModule]="contentModules[2]">
      </app-module-a050>
    </div>

    <div class="w-full pt-10 pb-12 sm:py-scale-h-27">
      <app-module-a020
        [contentModule]="contentModules[3]"
      ></app-module-a020>
    </div>
  </div>

  <div class="w-full h-px bg-gray-300"></div>

  <div class="container">
    <div class="w-full pt-10 pb-12 sm:py-scale-h-27">
      <app-module-a030
        [contentModule]="contentModules[4]"
      ></app-module-a030>
    </div>

    <div class="pt-2 pb-10 sm:py-10">
      <app-module-a070
        [contentModule]="contentModules[5]">
      </app-module-a070>
    </div>

  </div>

  <div class="w-full h-px bg-gray-300 sm:mt-10"></div>

  <div class="container">

    <div class="w-full py-6 sm:pt-scale-h-18 sm:pb-scale-h-27">
      <app-module-a040
        [contentModule]="contentModules[6]"
      ></app-module-a040>
    </div>

    <div class="w-full pt-2 pb-10 sm:py-10">
      <app-module-a060
        [contentModule]="contentModules[7]"
      >
      </app-module-a060>
    </div>

  </div>

  <div class="w-full h-px bg-gray-300"></div>

  <div class="container">
    <div class="w-full pt-10 pb-12 sm:py-scale-h-27">
      <app-module-a020
        [contentModule]="contentModules[8]"
      ></app-module-a020>
    </div>

    <div class="w-full pt-2 pb-10 sm:py-scale-h-27">
      <app-module-a050 [contentModule]="contentModules[9]">
      </app-module-a050>
    </div>
  </div>

  <div class="w-full h-px bg-gray-300"></div>

  <div class="container">

    <div class="w-full py-6 sm:pt-scale-h-18 sm:pb-scale-h-27">
      <app-module-a040
        [contentModule]="contentModules[10]"
      ></app-module-a040>
    </div>

    <div class="pt-2 pb-10 sm:py-10">
      <app-module-a070
        [contentModule]="contentModules[11]">
      </app-module-a070>
    </div>

    <div class="w-full pt-10 pb-12 sm:py-scale-h-27">
      <app-module-a020
        [contentModule]="contentModules[12]"
      ></app-module-a020>
    </div>
  </div>

  <div class="parallax-element">
    <div class="container">
      <div class="w-full pt-2 pb-10 sm:py-10">
        <app-module-a060
          [contentModule]="contentModules[13]"
        >
        </app-module-a060>
      </div>
    </div>

    <div class="w-full h-px bg-gray-300"></div>

    <div class="container">

      <div class="w-full py-6 sm:pt-scale-h-18 sm:pb-scale-h-27">
        <app-module-a040
          [contentModule]="contentModules[14]"
        ></app-module-a040>
      </div>
    </div>
  </div>

  <div class="parallax-element">
    <div class="w-full">
      <app-module-a100
        [contentModule]="contentModules[15]"
      ></app-module-a100>
    </div>
  </div>

  <div class="container">
    <div class="w-full pt-10 pb-12 sm:py-scale-h-27">
      <app-module-a080
        [contentModule]="contentModules[16]"
      ></app-module-a080>
    </div>
  </div>

  <div class="bg-gray-300 w-full py-10 sm:py-scale-h-27">
    <div class="w-full h-px bg-gray-300"></div>
    <div class="container">
      <div class="w-full">
        <app-module-a120
          [contentModule]="contentModules[17]"
        ></app-module-a120>
      </div>
    </div>
  </div>

</div>


