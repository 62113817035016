export const routes: any = {
  me: {
    homepage: '',
    contact: 'kontakt',
    work: ''
  },
  en: {
    homepage: '',
    contact: 'contact',
    work: 'work'
  },
};

export enum RouteName {
  HOME = 'homepage',
  CONTACT = 'contact',
  WORK = 'work',
  ABOUT = 'about',
  CAREERS = 'careers',
}

export let localeMapping: {} = {
  'sr-Latn-ME': 'me',
  'en-US': 'en',
  en: 'en',
};

export let currentLocale: string = $localize`:@@locale_code_sr_or_en|CurrentLocaleShortCode|CurrentLocaleShortCode:me`;
