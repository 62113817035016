<div class="container pt-13 pb-38 sm:pt-scale-h-38 sm:pb-scale-h-70">
  <div class="w-full flex flex-col sm:flex-row sm:items-end">
    <div class="w-full sm:w-12/24">
      <h2 class="animated-text font-inter uppercase text-52 text-scale-140 leading-100 font-900">
        <span class="animated-text-row">Featured</span>
        <span class="animated-text-row">projects</span>
      </h2>
    </div>
    <div class="w-full sm:w-13/24 sm:pl-1/24 mt-8 sm:mt-0">
      <div class="w-16/24 sm:w-full">
        <span class="text-18 text-scale-24 leading-122 sm:leading-150 font-700">
          Great products are designed & built here.
        </span>
      </div>
      <p class="mt-3 sm:mt-scale-h-3 text-16 text-scale-22 leading-137 sm:leading-164 font-300">
        Designing successful digital products is not just about combining design and development. It requires a deep
        understanding of users, constant innovation, and commitment to delivering beautiful and functional experiences.
        Creating these products is driven by passion, creativity, and a desire to make a meaningful impact.
      </p>
    </div>
  </div>

  <div class="mt-9 sm:mt-scale-h-26 w-full flex flex-row">
    <div class="hidden sm:block sm:w-6/24 sm:mt-scale-h-3">
      <div class="flex items-start">
        <a [href]="typeformService.contactUrl"
           title="Let's work together"
           target="_blank"
           class="btn btn-primary">
            <span class="btn-body text-scale-20">
              Let's work together
            </span>
        </a>
      </div>
    </div>
    <div class="sm:mt-scale-h-10 border-t border-gray-300 w-full sm:w-18/24">
      <div *ngFor="let project of projects"
           (mouseenter)="projectService.startVideo($event)"
           (mouseleave)="projectService.pauseVideo($event)"
           class="group hover:cursor-pointer"
           tabindex="0">
        <div class="py-9 sm:py-scale-h-12 border-b border-gray-300">
          <div class="grid grid-cols-18 gap-y-6 auto-rows-max">
            <div class="order-last sm:order-first col-span-18 sm:col-span-6">
              <div class="flex flex-col sm:h-full">
                <div class="w-full sm:flex-1">
                  <div class="mb-3 sm:mb-0 flex items-center gap-2 sm:gap-scale-w-3">
                    <fa-icon [icon]="faArrowUpRight"
                             class="block w-3 sm:w-scale-w-4 sm:min-w-[14px] aspect-square leading-100"></fa-icon>
                    <span class="text-16 text-scale-20 font-500 uppercase
                    group-focus:animate-pulse group-active:animate-none">
                      Case study coming soon
                    </span>
                  </div>
                </div>
                <div class="w-20/24 sm:w-full flex flex-col gap-y-10 sm:gap-y-scale-h-10">
                  <p class="text-26 text-scale-48 leading-122 font-800">
                    {{ project.title }}
                  </p>
                </div>
              </div>
            </div>
            <div class="order-first sm:order-last col-span-18 sm:col-span-12 sm:pl-6">
              <div class="w-full aspect-[3/2] rounded-3 sm:rounded-4 overflow-hidden">
                <app-project-graphics
                  [project]="project"
                ></app-project-graphics>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
