import { RouteName } from '../routes';
import { SeoItem } from '../services/seo/seo-item.model';
import { environment } from '../../../environments/environment';

export const seoRulesAbout = {
  en: new SeoItem({
    title: "Get to know us | About | Fleka",
    seo_title: "Get to know us | About | Fleka",
    seo_fb_title: "Get to know us | About | Fleka",
    seo_fb_description: "Creating digital products is a passion. Fleka is bringing together passionate professionals committed to making a meaningful impact.",
    seo_fb_image_url: environment.websiteUrl + "/assets/img/seo/seo_1200x630.jpg",
    seo_description: "Creating digital products is a passion. Fleka is bringing together passionate professionals committed to making a meaningful impact.",
    seo_tw_title: "Get to know us | About | Fleka",
    seo_tw_description: "Creating digital products is a passion. Fleka is bringing together passionate professionals committed to making a meaningful impact.",
    seo_tw_image_url: environment.websiteUrl + "/assets/img/seo/seo_1200x675.jpg",
    seo_tw_card: "summary_large_image",
    seo_tw_site: "@fleka",
    seo_fb_type: 'website',
    seo_keywords: 'about',
    description: "Creating digital products is a passion. Fleka is bringing together passionate professionals committed to making a meaningful impact.",
    canonical_url: environment.websiteUrl + '/' + RouteName.ABOUT,
    route_name: RouteName.ABOUT,
    robots: '',
    url: environment.websiteUrl + '/' + RouteName.ABOUT,
  }),
};
