import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AboutLayoutComponent } from './components/about-layout/about-layout.component';
import { currentLocale, RouteName } from '../../Library/routes';
import { seoRulesAbout } from '../../Library/seo-rules/seo-rules-about';

const routes = [
  {
    path: '',
    component: AboutLayoutComponent,
    data: {
      routeName: RouteName.ABOUT,
      seoItem: seoRulesAbout[currentLocale]
    }
  }

];
@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ]
})
export class AboutRoutingModule {
}
