import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { Subject } from 'rxjs';
import { MenuService } from '../../../@modules/menu/services/menu.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MutationService } from '../../../Library/services/mutation.service';
import { MenuItem } from '../../../@modules/menu/models/menu-item.model';
import { PlatformService } from '../../../@modules/platform/services/platform.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  menuItems: MenuItem[];

  locale: string;
  ngUnsubscribe = new Subject();

  scrollingContainer: HTMLElement;
  footerElement: HTMLElement;

  currentUrl: string;

  pagesWithDarkBackground: string[] = ['/work'];

  onDark: boolean;
  menuVisible: boolean = null;
  menuShrinked: boolean = false;
  logoHidden: boolean = false;

  constructor(
    private menuService: MenuService,
    private renderer: Renderer2,
    private router: Router,
    @Inject(LOCALE_ID) public localeCode: any,
    @Inject(DOCUMENT) private document: Document,
    private mutationService: MutationService,
    private changeDetectorRef: ChangeDetectorRef,
    private platformService: PlatformService,
  ) {
    this.onDark = this.pagesWithDarkBackground.includes(this.router.url);
  }

  ngOnInit(): void {
    this.menuItems = this.menuService.getMenuItems();
    this.currentUrl = this.router.url;

    this.router.events.subscribe(
      event => {

        if (event instanceof NavigationStart) {
          if (this.menuVisible) {
            this.closeMenu();
          }
        }

        if (event instanceof NavigationEnd) {
          this.currentUrl = event.url;
          this.onDark = this.pagesWithDarkBackground.includes(this.currentUrl);
          this.logoHidden = false;
        }
      }
    );
  }

  ngAfterViewInit(): void {
    this.mutationService.domChange.subscribe((dom: HTMLElement): void => {
      if (!this.platformService.isBrowser()) {
        return;
      }
      this.scrollingContainer = this.getScrollingContainer(dom);

      this.footerElement = this.scrollingContainer.querySelector('footer');


      this.addScrollListener(this.scrollingContainer);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleMenuDialog(): void {
    this.menuVisible = !this.menuVisible;

    if (this.platformService.isBrowser()) {
      this.toggleOverflowClass();
    }
  }

  closeMenu(): void {
    this.menuVisible = false;
    this.renderer.removeClass(this.document.body, 'overflow-hidden');
  }

  toggleOverflowClass(): void {
    if (this.menuVisible) {
      this.renderer.addClass(this.document.body, 'overflow-hidden');
    } else {
      this.renderer.removeClass(this.document.body, 'overflow-hidden');
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      this.menuVisible = false;
    }
  }

  getScrollingContainer(dom: HTMLElement): HTMLElement {
    return dom.querySelector('.parallax-wrapper') ?? dom;
  }

  addScrollListener(scrollableElement: HTMLElement): void {
    scrollableElement.addEventListener('scroll', this.hideMenuComponents);
  }

  hideMenuComponents = (event) => {
    const scrollableElement = event.target;

    this.menuShrinked = scrollableElement.scrollTop >= 236;

    this.logoHidden = this.isFooterInViewport();
    this.changeDetectorRef.detectChanges();
  }

  isFooterInViewport(): boolean {
    if (!this.footerElement) {
      return false;
    }

    const boundingRect: DOMRect = this.footerElement.getBoundingClientRect();

    return boundingRect.top < (window.innerHeight || document.documentElement.clientHeight);
  }

  onLogoClick(): void {
    if (this.currentUrl === '/') {
      this.scrollingContainer.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      this.router.navigate(['/']);
    }
  }

}
