<div class="container pb-20">
  <h2 class="inline-flex text-18 lg:text-22 text-black font-bold mb-6">Primary buttons</h2>
  <div class="flex flex-col lg:flex-row">
    <div class="flex flex-row">
      <div class="w-full lg:w-12/24">

        <div class="relative z-0">
          <button class="btn btn-primary group">
            <span class="btn-body">BTN PRIMARY</span>
            <span
              class="btn-arrow-wrapper">
              <fa-icon class="btn-arrow-icon"
                       [icon]="faArrowRight"></fa-icon>
            </span>
          </button>
        </div>

      </div>
      <div class="w-full lg:w-12/24">
      <pre
        class="p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code (click)="copyToClipboard(btnPrimary)" class="hover:cursor-pointer text-12 text-black leading-125">
          {{ btnPrimary }}
        </code>
      </pre>
      </div>
    </div>
  </div>

  <h2 class="inline-flex text-18 lg:text-22 text-black font-bold mb-6">Secondary buttons</h2>
  <div class="flex flex-col lg:flex-row">
    <div class="flex flex-row">
      <div class="w-full lg:w-12/24">

        <div class="relative z-0">
          <button class="btn btn-secondary group">
            <span class="btn-body">BTN SECONDARY</span>
            <span
              class="btn-arrow-wrapper">
              <fa-icon class="btn-arrow-icon"
                       [icon]="faArrowRight"></fa-icon>
            </span>
          </button>
        </div>

      </div>
      <div class="w-full lg:w-12/24">
        <pre
          class="p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code (click)="copyToClipboard(btnSecondary)" class="hover:cursor-pointer text-12 text-black leading-125">
            {{ btnSecondary }}
          </code>
        </pre>
      </div>
    </div>
  </div>

  <h2 class="inline-flex text-18 lg:text-22 text-black font-bold mb-6">Regular buttons</h2>
  <div class="flex flex-col lg:flex-row">
    <div class="flex flex-row">
      <div class="w-full lg:w-12/24">

        <button class="btn btn-primary btn-body">BTN REGULAR</button>

      </div>
      <div class="w-full lg:w-12/24">
        <pre
          class="p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code (click)="copyToClipboard(btnRegular)" class="hover:cursor-pointer text-12 text-black leading-125">
            {{ btnRegular }}
          </code>
        </pre>
      </div>
    </div>
  </div>
</div>


<div class="w-full bg-black py-20">
  <div class="container mb-20 space-y-10">
    <h2 class="inline-flex text-18 lg:text-22 text-white font-bold mb-6">On dark background</h2>
    <div class="flex flex-col lg:flex-row">
      <div class="flex flex-row">
        <div class="w-full lg:w-12/24">

          <div class="relative z-0">
            <button class="btn btn-primary--on--dark group">
              <span class="btn-body">BTN PRIMARY</span>
              <span
                class="btn-arrow-wrapper--on--dark">
                <fa-icon class="btn-arrow-icon"
                         [icon]="faArrowRight"></fa-icon>
              </span>
            </button>
          </div>

        </div>
        <div class="w-full lg:w-12/24">
          <pre
            class="p-3 bg-white border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
            <code (click)="copyToClipboard(btnPrimaryOnDark)" class="hover:cursor-pointer text-12 text-black leading-125">
              {{ btnPrimaryOnDark }}
            </code>
          </pre>
        </div>
      </div>
    </div>

    <div class="flex flex-col lg:flex-row">
      <div class="flex flex-row">
        <div class="w-full lg:w-12/24">

          <div class="relative z-0">
            <button class="btn btn-secondary--on--dark group">
              <span class="btn-body">BTN SECONDARY</span>
              <span
                class="btn-arrow-wrapper--on--dark">
                <fa-icon class="btn-arrow-icon"
                         [icon]="faArrowRight"></fa-icon>
              </span>
            </button>
          </div>

        </div>
        <div class="w-full lg:w-12/24">
          <pre class="p-3 bg-white border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
            <code (click)="copyToClipboard(btnSecondaryOnDark)" class="hover:cursor-pointer text-12 text-black leading-125">
              {{ btnSecondaryOnDark }}
            </code>
          </pre>
        </div>
      </div>
    </div>

    <div class="flex flex-col lg:flex-row">
      <div class="flex flex-row">
        <div class="w-full lg:w-12/24">

          <button class="btn btn-primary--on--dark btn-body">BTN REGULAR</button>

        </div>
        <div class="w-full lg:w-12/24">
          <pre
            class="p-3 bg-white border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
            <code (click)="copyToClipboard(btnRegularOnDark)"
                  class="hover:cursor-pointer text-12 text-black leading-125">
              {{ btnRegularOnDark }}
            </code>
          </pre>
        </div>
      </div>
    </div>

  </div>
</div>
