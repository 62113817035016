import { AfterViewInit, ChangeDetectorRef, Component, Inject, LOCALE_ID, OnInit, PLATFORM_ID } from '@angular/core';
import { TypographyService } from '../../../../Library/services/typography.service';
import { SeoService } from '../../../../Library/services/seo/seo.service';
import { BaseComponent } from '../../../../Library/compontents/base.component';
import { ActivatedRoute } from '@angular/router';
import { Job } from '../../../jobs/models/job.model';
import { JobService } from '../../../jobs/services/job.service';
import { SmoothScrollService } from '../../../../Library/services/smooth-scroll.service';
import { ParallaxService } from '../../../../Library/services/parallax.service';

@Component({
  selector: 'app-about-layout',
  templateUrl: './about-layout.component.html'
})
export class AboutLayoutComponent extends BaseComponent implements OnInit, AfterViewInit {

  jobs: Job[];

  constructor(
    private typographyService: TypographyService,
    protected route: ActivatedRoute,
    protected seoService: SeoService,
    private jobService: JobService,
    private smoothScrollService: SmoothScrollService,
    private changeDetectorRef: ChangeDetectorRef,
    private parallaxService: ParallaxService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) public platformId: any
  ) {
    super(route, seoService, locale, platformId);
  }

  ngOnInit(): void {
    this.jobService.all().subscribe(
      res => {
        if (res.data?.length > 0) {
          this.jobs = res.data;
          this.changeDetectorRef.detectChanges();

          this.parallaxService.createParallaxEffect();
          this.smoothScrollService.resizeScrollingContext();
        }
      }
    );
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.typographyService.init();
  }
}
