import { Component, Input } from '@angular/core';

@Component({
  selector: ' app-module-a070',
  templateUrl: './module-a070.component.html'
})
export class ModuleA070Component {

  @Input() contentModule: any;
}
