<div class="flex flex-col pt-10 sm:pt-scale-h-27 relative">
  <div class="container w-full flex flex-col sm:flex-row text-white">
    <div class="w-full sm:w-12/24 flex items-start">
      <h2 class="animated-text--on-dark text-48 text-scale-140 font-900 leading-100 uppercase">
        <span class="animated-text-row">Our Team</span>
      </h2>
    </div>
    <div class="w-full sm:w-12/24 mt-8 sm:mt-0">
      <p class="text-16 text-scale-32 leading-140 font-300">
        We're a dynamic mix of unique individuals - each of us a little quirky, incredibly dedicated, and united by a common mission and values.
        <br/>
        <br/>
        In all our endeavors, we aim to infuse our distinct personalities, leaving a bold and unforgettable mark in everything we do!
      </p>
    </div>
  </div>

  <div class="relative w-full flex flex-col pt-16 sm:pt-scale-h-36">

    <button *ngFor="let employee of employees; let i = index;"
            (mouseover)="updateImage(i)"
            (click)="updateImage(i)"
            [disabled]="!platformService.isMobile()"
            class="w-full border-b border-gray-500 text-white sm:hover:bg-white focus:bg-white transition-colors group">
      <div class="container flex justify-end">
        <div class="w-full sm:w-12/24 self-end flex flex-col sm:flex-row items-start justify-start
                    sm:justify-between sm:items-center py-4 sm:py-11 sm:gap-6">
          <div
            class="font-abril-bold text-22 text-scale-40 leading-111 sm:group-hover:text-black group-focus:text-black transition-colors relative">
            <span class="whitespace-nowrap">{{ employee.name }}</span>
            <app-animated-underline thickness="2"></app-animated-underline>
          </div>
          <span
            class="text-right text-16 text-scale-28 font-300 text-gray-300 sm:group-hover:text-gray-500 group-focus:text-gray-500 transition-colors">
            {{ employee.position }}
          </span>
        </div>
      </div>
    </button>

    <div
      class="w-full sm:w-8/24 flex items-end absolute bottom-0 top-0 h-full pointer-events-none">
      <div class="sticky bottom-0 w-full overflow-hidden">
        <div class="container sm:px-auto">
          <div class="relative w-full aspect-square">
            <div
              class="absolute w-full h-full rounded-6 bg-black transition-[top,opacity] ease-in-out duration-600 pointer-events-auto overflow-hidden"
              [ngClass]="imageLoaded ? 'top-0 opacity-100' : 'top-full opacity-0'">
              <div *ngFor="let employee of employees, let i = index"
                   class="w-full h-full transition-opacity duration-600 ease-in-out"
                   [ngClass]="currentImage === employee.image ? ' opacity-100' : 'opacity-0'">
                <img [ngSrc]="employee.image"
                     class="w-full aspect-square object-cover object-center"
                     [alt]="employee.name"
                     fill="true">
                <button (click)="closeImage()" class="sm:hidden absolute top-0 right-0">
                  <fa-icon
                    [icon]="faXmark"
                    class="flex justify-center items-center text-white w-7 h-7 p-8">
                  </fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


