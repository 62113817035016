import { Component, EventEmitter, Output } from '@angular/core';
import { MenuService } from '../../../@modules/menu/services/menu.service';
import { ContactService } from '../../../@modules/contact/services/contact.service';
import { MenuItem } from '../../../@modules/menu/models/menu-item.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent {

  menuItems: MenuItem[];
  contactDetails: any;

  emailVisible: boolean = false;

  @Output() closeMenu = new EventEmitter<boolean>();

  constructor(
    private menuService: MenuService,
    private contactService: ContactService
  ) {
    this.menuItems = this.menuService.getMenuItems();
    this.contactDetails = this.contactService.getContactDetails();
  }

  exitMenu(): void {
    this.closeMenu.emit(true);
  }

  showEmail(): void {
    this.emailVisible = true;
  }
}
