import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/@shared/shared.module';
import { MediaModule } from 'src/app/Library/media/media.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ContactLayoutComponent } from './components/contact-layout/contact-layout.component';
import { ContactRoutingModule } from './contact-routing.module';
import { ContactHeroComponent } from './components/contact-hero/contact-hero.component';
import { AnimatedUnderlineComponent } from '../../@shared/components/animated-underline/animated-underline.component';


@NgModule({
  declarations: [
    ContactLayoutComponent,
    ContactHeroComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MediaModule,
    FontAwesomeModule,
    SharedModule,
    ContactRoutingModule,
    AnimatedUnderlineComponent,
  ],
})
export class ContactModule {
}
