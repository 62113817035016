<div class="w-full pt-12 pb-20 sm:py-scale-h-27 flex flex-col">
  <div class="w-full flex flex-col border-b border-gray-500">
    <div class="container grid grid-cols-1 sm:grid-cols-2 gap-scale-w-44">
      <div class="w-full font-abril-bold text-white">
        <h2 class="text-28 text-scale-68 leading-115">Crafting digital products that people fall in love with.</h2>
      </div>
      <div class="w-full font-inter text-white font-300">
        <p class="text-16 text-scale-32 leading-115">
          Creating digital products isn't just a job for us - it's a passion.  Each project is a labor of love, meticulously crafted, where we focus on creating products that not only deliver value but also bring joy and satisfaction to users, ensuring a memorable and engaging digital experience.
        </p>
        <br>
        <p class="text-16 text-scale-32 leading-115">
          Since 2008, our studio has been a dynamic and inspiring space, bringing together passionate professionals committed to making a meaningful impact. We always go the extra mile, consistently delivering beyond expectations.
        </p>
      </div>
    </div>

    <app-image-carousel [imagePaths]="imagePaths"></app-image-carousel>
  </div>
  <app-about-employee-list></app-about-employee-list>
</div>

