<div class="w-full h-screen relative flex flex-col justify-end pb-12 sm:pb-scale-h-30">
  <div class="container">
    <h1 class="animated-text text-48 text-scale-120 leading-105 sm:leading-[0.85] font-900 uppercase -mr-1 sm:mr-0">
      <span class="animated-text-row">Let's create</span>
      <span class="animated-text-row">something</span>
      <span class="animated-text-row">special</span>
    </h1>
  </div>

  <div class="container pt-7 sm:pt-0 flex justify-center sm:justify-end">
    <div class="w-full sm:w-9/24 flex sm:justify-end">
      <div class="w-full flex flex-col">
        <p
          class="w-20/24 sm:w-full text-black text-16 text-scale-24 leading-137 sm:leading-150 font-300 sm:pr-scale-w-3">
            It's always the right time for good ideas and interesting suggestions!
            If you think that we can make a good team together, don’t hesitate to let us know.
        </p>
        <div class="w-half sm:w-auto self-start relative z-0">
          <a [href]="typeformService.contactUrl"
             title="Get in touch"
             target="_blank"
             class="btn btn-secondary group mt-7 sm:mt-scale-h-8 self-start">
            <span class="btn-body text-14 text-scale-20">Get in touch</span>
            <span class="btn-arrow-wrapper">
              <fa-icon class="btn-arrow-icon"
                       [icon]="faArrowRight">
              </fa-icon>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="platformService.isBrowser()" class="curtain-element"></div>
</div>
