<div class="bg-white border-t-8 border-t-black pt-6 pb-4 border-b border-b-black sticky top-0 z-100">
  <div class="container flex flex-row justify-between items-center">
    <div class="flex flex-1 flex-row items-center">
      <img ngSrc="/assets/img/logos/logo.png" alt="Fleka" width="512" height="512" class="w-16 mr-8">
      <h2 class="text-24 lg:text-26 text-black uppercase font-700">Design system</h2>
    </div>
    <select id="components" name="components" class="text-black" [(ngModel)]="activeSection">
      <option *ngFor="let section of sections" [ngValue]="section.value" class="text-black">
        {{ section.label }}
      </option>
    </select>
  </div>
</div>

<div class="py-12">
   <app-ds-scalable-sizes *ngIf="!activeSection || activeSection === 1"></app-ds-scalable-sizes>
  <app-ds-buttons *ngIf="!activeSection || activeSection === 2"></app-ds-buttons>
  <app-ds-modules *ngIf="!activeSection || activeSection === 3"></app-ds-modules>
</div>


