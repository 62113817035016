<div class="keen-slider" #sliderRef>
  <div *ngFor="let brandLogo of brandLogos" class="keen-slider__slide px-4 sm:px-scale-w-12 flex items-center justify-center">
    <img ngSrc="{{ onDark ? brandLogo.onDark : brandLogo.onLight }}" alt="{{ brandLogo.alt }}"
         [priority]="priority"
         class="w-full"
         width="134" height="86"
    />
  </div>
</div>

