import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MutationService } from './mutation.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import Lenis from '@studio-freight/lenis';

@Injectable({
  providedIn: 'root'
})
export class SmoothScrollService {

  private scroller: Lenis;

  constructor(
      @Inject(PLATFORM_ID) public platformId: any,
      @Inject(DOCUMENT) private document: HTMLElement,
      private mutationService: MutationService
  ) {
  }

  public init(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.mutationService.domChange.subscribe((dom: HTMLElement) => {
      this.addSmoothScroll(dom);
    })
  }

  public resizeScrollingContext(): void {
    this.scroller?.resize();
  }

  private addSmoothScroll(dom: HTMLElement) {
    let wrapperElement: HTMLElement = dom.querySelector('.parallax-wrapper');

    if (!wrapperElement) {
      wrapperElement = document.documentElement;
    }

    this.scroller = new Lenis({
      wrapper:  wrapperElement
    });

    const raf = (time): void => {
      this.scroller.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
  }

}
