import { RouteName } from '../routes';
import { SeoItem } from '../services/seo/seo-item.model';
import { environment } from '../../../environments/environment';


export const seoRulesHomepage = {
    en: new SeoItem({
      title: "FLEKA",
      seo_title: "Fleka | Crafting digital products that people fall in love with",
      seo_fb_title: "Fleka | Crafting digital products that people fall in love with",
      seo_fb_description: "We are a UX/UI agency in Podgorica, Montenegro. We conceptualize, design and develop digital products, websites and mobile apps.",
      seo_fb_image_url: environment.websiteUrl + "/assets/img/seo/seo_1200x630.jpg",
      seo_description: "We are a UX/UI agency in Podgorica, Montenegro. We conceptualize, design and develop digital products, websites and mobile apps.",
      seo_tw_title: "Fleka | Crafting digital products that people fall in love with",
      seo_tw_description: "We are a UX/UI agency in Podgorica, Montenegro. We conceptualize, design and develop digital products, websites and mobile apps.",
      seo_tw_image_url: environment.websiteUrl + "/assets/img/seo/seo_1200x675.jpg",
      seo_tw_card: "summary_large_image",
      seo_tw_site: "@fleka",
      seo_fb_type: "website",
      seo_keywords: "homepage",
      description: "We are a UX/UI agency in Podgorica, Montenegro. We conceptualize, design and develop digital products, websites and mobile apps.",
      route_name: RouteName.HOME,
      canonical_url: environment.websiteUrl,
      robots: "",
      url: environment.websiteUrl,
    }),
};
