import { AfterViewInit, Component, ElementRef, Inject, Input, PLATFORM_ID, ViewChild } from '@angular/core';
import { MutationService } from '../../../Library/services/mutation.service';
import { PlatformService } from '../../../@modules/platform/services/platform.service';
import { isPlatformBrowser } from '@angular/common';
import Lenis from '@studio-freight/lenis';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent implements AfterViewInit {

  @Input() imagePaths: string[];

  @ViewChild('imagesCarousel', {static: true}) imagesCarousel: ElementRef;

  smoothScroller: Lenis;

  isMobile: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private mutationService: MutationService,
    private platformService: PlatformService) {
    this.isMobile = this.platformService.isMobile();
  }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.mutationService.domChange.subscribe((dom: HTMLElement): void => {
      const wrapperElement: HTMLElement = dom.querySelector('.parallax-wrapper') ?? dom;
      const carouselElement = this.imagesCarousel.nativeElement;

      this.addSmoothScrollToElement(carouselElement);

      wrapperElement.addEventListener('scroll', () => {
        const carouselBoundingRect = carouselElement.getBoundingClientRect();

        if (this.isInViewport(carouselBoundingRect)) {
          const scrollAmountPercentage: number = 1 - carouselBoundingRect.top / (wrapperElement.clientHeight - carouselElement.clientHeight);
          const scrollAmountValue: number = scrollAmountPercentage * (carouselElement.scrollWidth - carouselElement.clientWidth);
          this.smoothScroller.scrollTo(scrollAmountValue);
        }
      });
    });
  }

  addSmoothScrollToElement(scrollableElement: HTMLElement): void {
    this.smoothScroller = new Lenis({
      wrapper: scrollableElement,
      content: scrollableElement,
      orientation: 'horizontal',
    });

    const raf = (time): void => {
      this.smoothScroller.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
  }

  isInViewport(boundingRect: DOMRect): boolean {
    return (
      boundingRect &&
      boundingRect.top >= 0 &&
      boundingRect.left >= 0 &&
      boundingRect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      boundingRect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
}
