<div class="min-h-screen container pt-9 sm:pt-scale-h-18 pb-38 sm:pb-scale-h-40 flex flex-col">
  <span class="text-16 text-scale-24 text-white uppercase">
    #OUR<span class="font-700">PRODUCTS</span>
  </span>
  <div class="pt-8 sm:pt-scale-h-18 flex-1 flex flex-col-reverse sm:flex-row sm:items-center gap-y-9">
    <div class="w-full sm:w-7/24 sm:mr-1/24 flex flex-col items-start text-white">
      <h4 class="text-36 text-scale-66 font-700">
        Sken
      </h4>
      <span class="mt-3 sm:mt-scale-h-6 text-24 text-scale-44 leading-120 font-300">
        Your smart expense tracking app. Effortlessly understand your monthly spending patterns and take control of your finances.
      </span>

      <div class="mt-6 sm:mt-scale-h-9 relative z-0">
        <a href="https://www.sken.me/"
           title="Sken"
           target="_blank"
           class="btn btn-secondary--on--dark group !bg-s-350 sm:hover:!bg-white">
          <span class="btn-body">Learn more</span>
          <span
            class="btn-arrow-wrapper--on--dark opacity-0 sm:group-hover:opacity-100 !transition-[right,background-color,transform,opacity] !duration-500">
            <fa-icon class="btn-arrow-icon"
              [icon]="faArrowRight">
            </fa-icon>
          </span>
        </a>
      </div>
    </div>
    <div class="w-full sm:w-16/24 flex items-center justify-between">
      <div class="w-12/24 sm:w-8/24 flex flex-col gap-4 sm:gap-scale-h-9">
        <img ngSrc="assets/img/homepage/sken/sken_gfx1.jpg"
             width="585" height="246" alt="Sken graphics" class="w-full rounded sm:rounded-3"/>
        <div class="flex flex-col gap-2 sm:gap-scale-h-3">
          <img ngSrc="assets/img/homepage/sken/sken_gfx2.jpg"
               width="585" height="130" alt="Sken graphics" class="w-full rounded sm:rounded-3"/>
          <img ngSrc="assets/img/homepage/sken/sken_gfx3.jpg"
               width="585" height="131" alt="Sken graphics" class="w-full rounded sm:rounded-3"/>
          <img ngSrc="assets/img/homepage/sken/sken_gfx4.jpg"
               width="585" height="131" alt="Sken graphics" class="w-full rounded sm:rounded-3"/>
        </div>
      </div>
      <div class="z-back w-20/24 sm:w-19/24 -ml-8/24 h-76 sm:h-full rounded-2 sm:rounded-3 overflow-hidden relative">
        <img ngSrc="assets/img/homepage/sken/sken_image.jpg" alt="Sken hero image" width="1458" height="1085"
             class="h-full object-cover object-left"/>
      </div>
    </div>
  </div>
</div>
