import { Component, OnInit } from '@angular/core';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-ds-buttons',
  templateUrl: './ds-buttons.component.html'
})
export class DsButtonsComponent implements OnInit {

  btnPrimary: string;
  btnPrimaryOnDark: string;
  btnSecondary: string;
  btnSecondaryOnDark: string;
  btnRegular: string;
  btnRegularOnDark: string;
  faArrowRight = faArrowRight;

  ngOnInit(): void {

    this.btnPrimary = `
<div class="relative z-0">
  <button class="btn btn-primary group">
    <span class="btn-body">BTN PRIMARY</span>
    <span
      class="btn-arrow-wrapper">
      <fa-icon class="btn-arrow-icon"
        [icon]="faArrowRight">
      </fa-icon>
    </span>
  </button>
</div>`;

    this.btnSecondary = `
<div class="relative z-0">
  <button class="btn btn-secondary group">
    <span class="btn-body">BTN SECONDARY</span>
    <span class="btn-arrow-wrapper">
      <fa-icon class="btn-arrow-icon"
        [icon]="faArrowRight">
      </fa-icon>
    </span>
  </button>
</div>`;

    this.btnRegular =`
<button class="btn btn-primary btn-body">BTN REGULAR</button>`;

    this.btnPrimaryOnDark =`
<div class="relative z-0">
  <button class="btn btn-primary--on--dark group">
    <span class="btn-body">BTN PRIMARY</span>
    <span
      class="btn-arrow-wrapper--on--dark">
      <fa-icon class="btn-arrow-icon"
        [icon]="faArrowRight">
      </fa-icon>
    </span>
  </button>
</div>`;

    this.btnSecondaryOnDark = `
<div class="relative z-0">
  <button class="btn btn-secondary--on--dark group">
    <span class="btn-body">BTN SECONDARY</span>
    <span
      class="btn-arrow-wrapper--on--dark">
      <fa-icon class="btn-arrow-icon"
        [icon]="faArrowRight">
      </fa-icon>
    </span>
  </button>
</div>`;

    this.btnRegularOnDark = `
<button class="btn btn-primary--on--dark btn-body">BTN REGULAR</button>`;
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
  }

}
