<div class="swiper module-a060-swiper w-full h-[330px] sm:h-[96vh]">
  <div class="swiper-wrapper w-full h-full">
    <div class="h-full sm:h-[96vh] relative bg-gray-300 rounded-4 sm:rounded-12 swiper-slide relative overflow-hidden">
      <picture *ngIf="contentModule.firstImageDesktop && contentModule.firstImageMobile">
        <source media="(min-width:768px)" [srcset]="contentModule.firstImageDesktop">
        <source media="(max-width:768px)" [srcset]="contentModule.firstImageMobile">
        <img [src]="contentModule.firstImageDesktop" alt="Fleka" class="absolute inset-0 object-cover object-center w-full h-full">
      </picture>
    </div>
    <div class="h-full relative bg-gray-300 rounded-4 sm:rounded-12 swiper-slide relative overflow-hidden">
      <picture *ngIf="contentModule.secondImageDesktop && contentModule.secondImageMobile">
        <source media="(min-width:768px)" [srcset]="contentModule.secondImageDesktop">
        <source media="(max-width:768px)" [srcset]="contentModule.secondImageMobile">
        <img [src]="contentModule.secondImageDesktop" alt="Fleka" class="absolute inset-0 object-cover object-center w-full h-full">
      </picture>
    </div>
  </div>

</div>
