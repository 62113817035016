<div #videoModule class="relative w-full sm:h-screen flex items-center justify-center bg-gray-100">
  <div class="w-full sm:w-screen sm:min-w-[1920px] sm:min-h-screen aspect-video">
    <div [id]="container_id" class="object-center"
         [ngClass]="fullscreen ? 'pointer-events-auto' : 'pointer-events-none'"></div>
  </div>

  <div
    class="group absolute inset-0 flex items-center justify-center cursor-none"
    (click)="openFullscreen()">
    <div #playButton class="play-button">
      <img ngSrc="assets/img/logos/play.png" width="100" height="100" alt="play" class="block play-button-animation">
    </div>
  </div>
</div>

