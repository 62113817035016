import { Component, Input } from '@angular/core';
import { MenuService } from '../../../@modules/menu/services/menu.service';
import { ContactService } from '../../../@modules/contact/services/contact.service';
import { Router } from '@angular/router';
import { MutationService } from '../../../Library/services/mutation.service';
import { MenuItem } from '../../../@modules/menu/models/menu-item.model';
import { PlatformService } from '../../../@modules/platform/services/platform.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {

  @Input() colors_inverted: boolean = false;

  currentYear: number;
  menuItems: MenuItem[];
  contactDetails: any;

  emailVisible: boolean = false;

  scrollingContainer: HTMLElement;

  constructor(
    private menuService: MenuService,
    protected contactService: ContactService,
    private router: Router,
    private mutationService: MutationService,
    private platformService: PlatformService,
  ) {
    this.currentYear = new Date().getFullYear();
    this.menuItems = this.menuService.getMenuItems();
    this.contactDetails = this.contactService.getContactDetails();

    this.mutationService.domChange.subscribe(dom => {
      if (this.platformService.isBrowser()) {
        this.scrollingContainer = this.getScrollingContainer(dom);
      }
    });
  }

  getScrollingContainer(dom: HTMLElement): HTMLElement {
    return dom.querySelector('.parallax-wrapper') ?? dom;
  }

  onLogoClick(): void {
    if (this.router.url === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      this.router.navigate(['/']);
    }
  }

  showEmail(): void {
    this.emailVisible = true;
  }
}
