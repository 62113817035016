import { AfterViewInit, Component, Inject, LOCALE_ID, OnDestroy, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocaleService } from '../../../../Library/services/locale.service';
import { BaseComponent } from '../../../../Library/compontents/base.component';
import { SeoService } from '../../../../Library/services/seo/seo.service';
import { TypographyService } from '../../../../Library/services/typography.service';

@Component({
  selector: 'app-homepage-layout',
  templateUrl: './homepage-layout.component.html'
})
export class HomepageLayoutComponent extends BaseComponent implements OnDestroy, AfterViewInit {

  constructor(
    protected route: ActivatedRoute,
    protected seoService: SeoService,
    private localeService: LocaleService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) public platformId: any,
    private typographyService: TypographyService,
  ) {
    super(route, seoService, locale, platformId);
    this.localeService.setCurrentRouteTranslationsForItem();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.typographyService.init();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
