import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ContactLayoutComponent } from './components/contact-layout/contact-layout.component';
import { currentLocale, RouteName } from '../../Library/routes';
import { seoRulesContact } from '../../Library/seo-rules/seo-rules-contact';

const routes = [
  {
    path: '',
    component: ContactLayoutComponent,
    data: {
      routeName: RouteName.CONTACT,
      seoItem: seoRulesContact[currentLocale]
    },
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
})
export class ContactRoutingModule {
}
