import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TypeformService {

  public jobApplicationUrl: string = 'https://fillit.typeform.com/to/lfnWqNOz';
  public contactUrl: string = 'https://fillit.typeform.com/to/OjBDYn?typeform-source=www.fleka.me';
}
