<div class="w-full pt-10 pb-18 sm:pt-scale-h-22 sm:pb-scale-h-19 flex flex-col font-inter">
  <div class="container flex flex-col">
    <h2 class="font-abril-bold text-30 text-scale-72 text-black">Careers</h2>
    <span class="w-full sm:w-19/24 text-24 text-scale-56 font-300 text-black leading-120">
          Join us and leverage your expertise to engage in projects that stretch your creative limits.
    </span>
    <div class="flex justify-start items-center">
      <div class="relative z-0">
        <a [href]="typeformService.jobApplicationUrl"
           title="Join our team"
           target="_blank"
           class="btn btn-primary group mt-5 mb-10 sm:mt-scale-h-16 sm:mb-scale-h-24 self-start">
          <span class="btn-body">Join our team</span>
          <span class="btn-arrow-wrapper">
            <fa-icon class="btn-arrow-icon" [icon]="faArrowRight"></fa-icon>
          </span>
        </a>
      </div>
    </div>
    <span *ngIf="jobs?.length" class="text-lef text-18 text-scale-24 mb-8 font-700">Currently open positions:</span>
  </div>
  <div *ngIf="jobs?.length" class="w-full">
    <a *ngFor="let job of jobs"
       [routerLink]="['/', careersRoute, job.slug]"
       [title]="job.title"
       class="group block w-full bg-white border-y border-gray-300">
      <div class="container flex justify-between items-center py-scale-h-8">
        <span class="text-16 text-scale-34 font-700">{{ job.title }}</span>
        <span *ngIf="job.submitDeadline" class="hidden sm:block">deadline
          <span class="font-700 text-18 text-scale-24">{{ job.submitDeadline | date:'MMMM d, y'}}</span>
        </span>
        <div class="relative self-center">
          <span class="uppercase text-14 text-scale-24 font-300 whitespace-nowrap">READ MORE</span>
          <app-animated-underline [origin]="'right'"></app-animated-underline>
        </div>
      </div>
    </a>
  </div>
</div>
