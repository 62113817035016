<div class="w-full flex flex-col sm:flex-row">
  <div class="w-full sm:w-6/24">
    <span *ngIf="contentModule.title"
          class="text-24 text-scale-24 leading-150 font-700 mt-6">
      {{ contentModule.title }}
    </span>
  </div>
  <div class="w-full sm:w-11/24 sm:mr-1/24 flex flex-col">
      <span *ngIf="contentModule.firstSubtitle"
            class="font-300 text-20 text-scale-24 leading-120 underline">
        {{ contentModule.firstSubtitle }}
      </span>
    <p
      *ngIf="contentModule.firstDescription"
      class="text-18 text-scale-24 font-300 leading-140 sm:leading-150 mt-6 sm:mt-scale-h-10"
      [innerHtml]="contentModule.firstDescription">
    </p>
  </div>
  <div class="w-full sm:w-6/24 flex flex-col">
      <span *ngIf="contentModule.secondSubtitle"
            class="font-300 text-20 text-scale-24 leading-120 underline mt-10 sm:mt-0">
        {{ contentModule.secondSubtitle }}
      </span>
    <p
      *ngIf="contentModule.secondDescription"
      class="text-18 text-scale-24 font-300 leading-140 sm:leading-150 mt-6 sm:mt-scale-h-10"
      [innerHtml]="contentModule.secondDescription">
    </p>
  </div>
</div>
