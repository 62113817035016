import { Component, Inject, LOCALE_ID, OnInit, PLATFORM_ID } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ResolveStart, Router } from '@angular/router';

import { GlobalService } from './Library/services/global.service';
import { SeoService } from './Library/services/seo/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'fleka-website';

  constructor(
      private globalService: GlobalService,
      private seoService: SeoService,
      private router: Router,
      @Inject(LOCALE_ID) public locale: string,
      @Inject(PLATFORM_ID) private platformId,
      @Inject(DOCUMENT) private document: Document
  ) {

    globalService.is404.subscribe(is404 => {
    });

    router.events.subscribe((event) => {
      if (event instanceof ResolveStart) {
        if (globalService.is404.value === true) {
          globalService.is404.next(false);
        }
      }
    });
  }

  ngOnInit(): void {
    this.seoService.init();
  }
}
