export const moduleDescriptions: string[] = [
  ' Cosmetics spent two decades earning a devoted following, all without any advertising. The Montenegro-based\n' +
  '        company was worth 50 million euros, but they still hadn’t translated their ethos of “unselling” online. They\n' +
  '        asked Fleka to design, build, and ship a new e-commerce site and native applications.\n' +
  '        <br> <br>\n' +
  '        Working together, we created an immersive digital storefront inspired by the in-store experience. Our\n' +
  '        relationship grew into broad customer-experience strategy, including in-store and sales associate touchpoints,\n' +
  '        content integration, and a new loyalty program.',

  ' Ako znamo da brojke govore više od riječi, jasno je koliko je odgovoran i izazovan zadatak bio kreirati potpuno\n' +
  '      novo korisničko iskustvo za više od 140.000 članova Beauty Friend programa lojalnosti.\n' +
  '      <br> <br>\n' +
  '      Svaki kupac želi da ostvari popust i dobije više za svoj novac. Ali, previše plastike u novčaniku mnoge odbija od\n' +
  '      pristupanja nekom od mnoštva dostupnih programa lojalnosti na našem tržištu. Sa tom mišlju i puno pasije, ušli smo\n' +
  '      u projekat digitalizacije jedne od najpoznatijih loyalty kartica, koja na crnogorskom tržištu postoji više od 10\n' +
  '      godina - Beauty Friend kartice.',

  ' Feugiat ullamcorper quam viverra morbi at. Sed tortor justo felis elementum magna quam. Molestie posuere eu et\n' +
  '      facilisis. Quis egestas in aliquam libero ornare elit cras. Erat pretium congue varius amet in natoque augue id.\n' +
  '      <br><br>\n' +
  '      Facilisis velit ornare duis elit purus. Eget quis interdum orci cras est ornare. Arcu netus dapibus diam duis in\n' +
  '      libero maecenas massa. Urna posuere diam, vivamus ipsum at nunc ultrices. Ac in enim eget turpis pellentesque\n' +
  '      platea sem viverra quis. Convallis mattis lorem hendrerit ac. Proin congue vitae sed sed diam duis arcu, cursus\n' +
  '      ultricies.',

  'In eleifend leo volutpat habitant felis vel nibh. Ut elementum quis egestas tempor malesuada euismod hac iaculis. Volutpat,' +
  ' commodo phasellus malesuada quis nam augue sed id velit. Id vitae interdum aliquam luctus lectus sapien nec lacus. Sodales ' +
  'arcu dolor malesuada cursus in aliquam mattis mi. Proin arcu nibh vitae orci suspendisse urna et odio. Morbi parturient netus' +
  ' egestas congue. Lectus vel facilisis purus est pellentesque. Lectus tellus accumsan tellus ullamcorper proin. Sed tempor nisi, a, faucibus eu.',

  ' Adipiscing euismod hendrerit molestie volutpat est. Ut elementum felis, amet, et egestas elementum nibh lorem non.\n' +
  '      Nulla eu consectetur sed consectetur volutpat pharetra, malesuada dui sagittis.',
  'Feugiat ullamcorper quam viverra morbi at. Sed tortor justo felis elementum magna quam. Molestie posuere eu et facilisis. ' +
  'Quis egestas in aliquam libero ornare elit cras. Erat pretium congue varius amet in natoque augue id.\n' +
  '\n' +
  'Facilisis velit ornare duis elit purus. Eget quis interdum orci cras est ornare. Arcu netus dapibus diam duis in libero ' +
  'maecenas massa. Urna posuere diam, vivamus ipsum at nunc ultrices. Ac in enim eget turpis pellentesque platea sem viverra quis. Convallis mattis lorem hendrerit ac. Proin congue vitae sed sed diam duis arcu, cursus ultricies. '
  ,
  'Cosmetics spent two decades earning a devoted following, all without any advertising. The Montenegro-based company was worth 50 million euros, but they still hadn’t translated their ethos of “unselling” online. They asked Fleka to design, build, and ship a new e-commerce site and native applications.\n' +
  '\n' +
  'Working together, we created an immersive digital storefront inspired by the in-store experience. Our relationship grew into broad customer-experience strategy, including in-store and sales associate touchpoints, content integration, and a new loyalty program.'
  , 'Import and wholesale of cosmetic and decorative products, perfumes, jewelry and hair ornaments, personal hygiene products, baby programs, household products, pet food, as well as medical aids.'
];
