<div class="container flex flex-col py-12 sm:pt-scale-h-80 sm:pb-scale-h-60">
  <div class="flex justify-center items-center">
    <div class="w-full sm:w-20/24 flex flex-col justify-center font-900 text-white text-center leading-100 uppercase">
      <h2 *ngIf="!platformService.isMobile()" class="text-scale-160 animated-text--on-dark">
        <span class="animated-text-row">Have a project</span>
        <span class="animated-text-row">in mind?</span>
      </h2>
      <h2 *ngIf="platformService.isMobile()" class="text-48 animated-text--on-dark">
        <span class="animated-text-row">Have a</span>
        <span class="animated-text-row">project in</span>
        <span class="animated-text-row">mind?</span>
      </h2>
      <div
        class="flex flex-col sm:flex-row pt-12 sm:pt-scale-h-24 justify-center items-center text-white space-y-4 sm:space-y-0 sm:space-x-5">
        <div class="relative z-0">
          <a [href]="typeformService.contactUrl"
             title="Let's work together"
             target="_blank"
             class="btn btn-primary--on--dark group">
            <span class="btn-body">Let's work together</span>
            <span
              class="btn-arrow-wrapper--on--dark">
              <fa-icon class="btn-arrow-icon"
                [icon]="faArrowRight">
              </fa-icon>
            </span>
          </a>
        </div>

      </div>
    </div>
  </div>

</div>
