import { Component } from '@angular/core';
import { PlatformService } from '../../../platform/services/platform.service';
import { TypeformService } from '../../../../Library/services/typeform.service';

@Component({
  selector: 'app-homepage-hero',
  templateUrl: './homepage-hero.component.html'
})
export class HomepageHeroComponent {

  constructor(
    protected platformService: PlatformService,
    protected typeformService: TypeformService
  ) {
  }

  scrollToNextModule(): void {
    if (!this.platformService.isBrowser()) {
      return;
    }

    const anchor: HTMLElement = document.getElementById('scroll-anchor');

    if (anchor) {
      anchor.scrollIntoView({behavior: 'smooth'});
    }
  }

}
