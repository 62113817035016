<div class="w-full flex flex-col sm:flex-row">
  <div class="w-full sm:w-6/24">
    <span *ngIf="contentModule.title"
          class="font-700 text-24 leading-120 text-scale-24">
      {{contentModule.title}}
    </span>
  </div>
  <div class="w-full mt-8 sm:mt-0 sm:w-18/24 grid grid-cols-2 gap-x-4 gap-y-12 sm:gap-x-scale-w-44 sm:gap-y-scale-h-19">
    <div *ngFor="let value of contentModule.values; let i = index;"
         class="w-full flex flex-col">
      <span class="font-800 text-48 text-scale-130 text-black leading-120">{{ value }}</span>
      <span *ngIf="contentModule.descriptions[i]"
            class="mt-2 sm:mt-scale-h-6 font-300 text-16 text-scale-24 leading-137">
        {{ contentModule.descriptions[i]}}
      </span>
    </div>
  </div>
</div>
