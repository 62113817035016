<div class="w-full h-screen relative flex flex-col justify-end rounded-[20px]">
  <div class="container">
    <h1 class="animated-text text-48 text-scale-190 leading-105 sm:leading-[0.85] font-900 uppercase">
      <span class="animated-text-row">User</span>
      <span class="animated-text-row shifted-row">Experience</span>
      <span class="animated-text-row">Agency</span>
    </h1>
    <div class="w-full flex sm:justify-end pt-7 sm:pt-scale-h-2 pb-12 sm:pb-scale-h-10">
      <div class="w-18/24 sm:w-6/24">
        <p class="text-black text-16 text-scale-24 leading-137 sm:leading-150 font-300">
          Crafting digital products that people fall in love with.
        </p>
      </div>
    </div>
  </div>

  <div class="w-full border-t border-gray-300 pt-6 sm:pt-scale-h-6">
    <div class="container">
      <div class="flex flex-col sm:flex-row sm:items-center sm:justify-end">
        <a [href]="typeformService.contactUrl"
           title="Let's work together"
           target="_blank"
           class="btn btn-secondary btn-body mr-auto sm:mr-0 text-12 text-scale-18">
          Let's work together
        </a>
      </div>
      <div class="mt-11 sm:mt-scale-h-18 mb-16 sm:mb-scale-h-12 w-full text-center">
        <button class="font-300 text-18 text-scale-22 text-gray-300 hover:text-gray-500 transition-colors"
                (click)="scrollToNextModule()">
          Discover more
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="platformService.isBrowser()" class="curtain-element"></div>
</div>

<div id="scroll-anchor"></div>
