import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-module-a100',
  templateUrl: './module-a100.component.html',
  styleUrls: ['./module-a100.component.scss']
})
export class ModuleA100Component{
  @Input() contentModule: any;

}
