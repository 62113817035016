import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-animated-underline',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './animated-underline.component.html',
})
export class AnimatedUnderlineComponent {
  @Input() origin: string = 'left';
  @Input() thickness: number = 1;
  @Input() delay: number = 0;
  @Input() defaultVisible: boolean = false;
}
