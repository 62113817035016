import Bowser, { Parser } from 'bowser';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  bowser = null;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    @Optional() @Inject(REQUEST) private request: Request,
    @Inject(DOCUMENT) private document: any
  ) {}

  isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  isMobile(): boolean {
    return this.getBowser().getPlatformType(true) === 'mobile';
  }

  getBowser(): Parser.Parser {
    if (!this.bowser) {
      const userAgentString = this.isBrowser()
        ? window.navigator.userAgent
        : this.request.header('User-Agent');
      if (userAgentString) {
        this.bowser = Bowser.getParser(userAgentString);
      }
    }

    return this.bowser;
  }

  getCurrentUrl(): string {
    return this.document.location.href;
  }

  getCurrentPath(): string {
    return this.document.location.pathname;
  }
}
