<div class="parallax-wrapper">
  <div class="parallax-element bg-black">
    <app-work-hero></app-work-hero>
  </div>

  <div class="parallax-element bg-gray-100">
    <app-work-project-list></app-work-project-list>
  </div>

  <div class="parallax-element bg-black">
    <app-cta-module></app-cta-module>
  </div>

  <div class="parallax-element bg-white">
    <app-footer></app-footer>
  </div>
</div>
