import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ResourceService } from '../../resource/resource.service';
import { Media, MediaAdapter } from '../models/media.model';

@Injectable()

export class MediaService extends ResourceService<Media> {

  constructor(httpClient: HttpClient, adapter: MediaAdapter) {
    super('/media', httpClient, adapter);
  }

}
