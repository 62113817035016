import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WorkLayoutComponent } from './components/work-layout/work-layout.component';
import { RouteName } from '../../Library/routes';
import { seoRulesWork } from '../../Library/seo-rules/seo-rules-work';
import { CosmeticsComponent } from './components/cosmetics/cosmetics.component';

const routes = [
  {
    path: '',
    component: WorkLayoutComponent,
    data: {
      routeName: RouteName.WORK,
      seoItem: seoRulesWork['en']
    }
  },
  {
    path: 'cosmetics',
    component: CosmeticsComponent
  }

];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ]
})
export class WorkRoutingModule {
}
