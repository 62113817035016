<div class="w-full flex flex-col">
  <div class="w-full flex flex-col sm:w-20/24 sm:mx-2/24 text-left sm:text-center">
    <p *ngIf="contentModule.quote"
       class="font-300 text-22 text-black text-scale-36 leading-133">{{contentModule.quote}}</p>
    <span *ngIf="contentModule.author"
          class="text-black font-700 text-18 text-scale-32 text-left sm:text-center mt-8 sm:mt-scale-h-14 leading-120">
      {{contentModule.author}}
    </span>
    <span *ngIf="contentModule.position"
          class="text-left sm:text-center font-300 text-16 leading-137text-scale-34 mt-2 sm:mt-4">
      {{contentModule.position}}
    </span>
  </div>

</div>
