<footer class="w-full container rounded-t-32 flex flex-col font-inter"
        [ngClass]="colors_inverted ? 'text-white' : 'text-black'">
  <div class="w-full flex flex-col sm:flex-row pt-11 sm:pt-scale-h-20 sm:pb-scale-h-40">

    <div class="w-full sm:w-8/24">
      <button (click)="onLogoClick()">
        <img ngSrc="assets/img/logos/fleka_logo.svg"
             width="258"
             height="136"
             alt="Fleka"
             class="min-w-[91px] max-w-[129px] w-[91px] sm:w-scale-w-32">
      </button>
    </div>

    <div class="w-full sm:w-16/24 flex mt-8 sm:mt-0">
      <div class="w-half sm:w-[56.25%] flex flex-col gap-y-10 sm:gap-y-scale-h-14">
        <div
          class="w-full flex sm:hidden flex-col justify-start items-start gap-y-5 sm:gap-y-scale-h-9 font-300 text-16 text-scale-24 leading-150">
          <span class="font-700">Menu:</span>
          <span *ngFor="let menuItem of menuItems">
            <app-menu-item [menuItem]="menuItem"></app-menu-item>
          </span>
        </div>
        <div
          class="w-full hidden sm:flex flex-col justify-start items-start gap-y-5 sm:gap-y-scale-h-9 font-300 text-16 text-scale-24 leading-150">
          <span class="font-700 text-16 text-scale-24 leading-150">Social Media:</span>
          <div class="group relative">
            <a [href]="contactDetails['instagram'].href" target="_blank">
              {{ contactDetails['instagram'].label }}
            </a>
            <app-animated-underline></app-animated-underline>
          </div>
          <div class="group relative">
            <a [href]="contactDetails['facebook'].href" target="_blank">
              {{ contactDetails['facebook'].label }}
            </a>
            <app-animated-underline></app-animated-underline>
          </div>
          <div class="group relative">
            <a [href]="contactDetails['linkedin'].href" target="_blank">
              {{ contactDetails['linkedin'].label }}
            </a>
            <app-animated-underline></app-animated-underline>
          </div>
        </div>
        <div
          class="w-full flex flex-col justify-start items-start gap-y-5 mt-10 sm:mt-0 sm:gap-y-scale-h-9 font-300 text-16 text-scale-24 leading-150">
          <span class="font-700 text-16 text-scale-24 leading-150">Location</span>
          <a [href]="contactDetails['address'].href" target="_blank"
             class="group flex flex-col items-start whitespace-nowrap">
            <span class="relative">
              {{ contactDetails['address'].label[0] }}
              <app-animated-underline></app-animated-underline>
            </span>
            <span class="relative">
              {{ contactDetails['address'].label[1] }}
              <app-animated-underline [delay]="100"></app-animated-underline>
            </span>
            <span class="relative">
              {{ contactDetails['address'].label[2] }}
              <app-animated-underline [delay]="200"></app-animated-underline>
            </span>
          </a>
        </div>
        <div
          class="w-full flex flex-col justify-start items-start gap-y-5 sm:gap-y-scale-h-9 mt-10 sm:mt-0 sm:pb-13 font-300 text-16 text-scale-24 leading-150">
          <span class="font-700">Contact:</span>
          <div>
            <div class="flex gap-1">
              <span>Phone:</span>
              <a [href]="contactDetails['tel'].href" class="group relative">
                {{ contactDetails['tel'].label }}
                <app-animated-underline></app-animated-underline>
              </a>
            </div>
            <div class="flex gap-1">
              <span>Email: </span>
              <button *ngIf="!emailVisible" (click)="showEmail()" class="group relative inline leading-100">
                Show
                <app-animated-underline [defaultVisible]="true"></app-animated-underline>
              </button>
              <a *ngIf="emailVisible" [href]="contactDetails['email'].href" class="group relative">
                {{ contactDetails['email'].label }}
                <app-animated-underline></app-animated-underline>
              </a>
            </div>
          </div>
        </div>
      </div>


      <div class="w-half sm:w-[43.75%] flex flex-col justify-start sm:justify-end items-start">
        <div
          class="w-full hidden sm:flex flex-col justify-start items-start gap-y-5 sm:gap-y-scale-h-9 font-300 text-16 text-scale-24 leading-150">
          <span class="font-700">Menu:</span>
          <div *ngFor="let menuItem of menuItems" class="group relative">
            <app-menu-item [menuItem]="menuItem"></app-menu-item>
            <app-animated-underline></app-animated-underline>
          </div>
        </div>
        <div
          class="w-full flex sm:hidden flex-col justify-start items-start gap-y-5 sm:gap-y-scale-h-9 font-300 text-16 text-scale-24 leading-150">
          <span class="font-700">Social Media:</span>
          <div class="group relative"></div>
          <a [href]="contactDetails['instagram'].href" target="_blank">
            {{ contactDetails['instagram'].label }}
          </a>
          <a [href]="contactDetails['facebook'].href" target="_blank">
            {{ contactDetails['facebook'].label }}
          </a>
          <a [href]="contactDetails['linkedin'].href" target="_blank">
            {{ contactDetails['linkedin'].label }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <div
    class="flex flex-col sm:flex-row sm:items-center w-full text-gray-300 font-300 pb-8 sm:pb-scale-h-12 mt-10 sm:mt-0 text-16 text-scale-24 leading-120">
    <div class="w-full sm:w-8/24"></div>
    <div class="w-full sm:w-9/24 sm:pr-scale-w-18 text-scale-18 font-400">
      <span>© fleka 2008-{{ currentYear }} fleka doo,</span>
      <br/>
      <span>Montenegro. All rights reserved.</span>
    </div>
    <span class="w-full sm:w-7/24 mt-3 sm:mt-0 text-black font-abril-bold"
          [ngClass]="colors_inverted ? 'text-white' : 'text-black'">
      Made with ❤️ by fleka
    </span>
  </div>

</footer>
