import { Component } from '@angular/core';

@Component({
  selector: 'app-about-team',
  templateUrl: './about-team.component.html'
})

export class AboutTeamComponent {

  imagePaths: string[] = [
    'assets/img/about/about_carousel_1.jpg',
    'assets/img/about/about_carousel_2.jpg',
    'assets/img/about/about_carousel_3.jpg',
    'assets/img/about/about_carousel_4.jpg',
  ]

}
