<div
  class="absolute bottom-0 {{origin}}-0 bg-current w-0
  group-focus:w-full sm:group-hover:w-full pointer-events-none
  transition-[background-color,width]"
  [ngStyle]="{
    'height': thickness + 'px',
    'transition-delay': delay + 'ms'
  }"
  [ngClass]="{
    'w-0 group-focus:w-full sm:group-hover:w-full' : !defaultVisible,
    'w-full group-focus:w-0 sm:group-hover:w-0': defaultVisible
  }">
</div>
