import { Component } from '@angular/core';
import { PlatformService } from '../../../platform/services/platform.service';

@Component({
  selector: 'app-work-hero',
  templateUrl: './work-hero.component.html'
})
export class WorkHeroComponent {
  constructor(protected platformService: PlatformService) {
  }

}
