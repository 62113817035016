import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-module-a040',
  templateUrl: './module-a040.component.html'
})
export class ModuleA040Component {

  @Input() contentModule: any;

}
