import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-module-a050',
  templateUrl: './module-a050.component.html'
})
export class ModuleA050Component  {

  @Input() contentModule: any;

}
