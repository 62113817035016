<div class="container pb-20 border-b border-black">
  <h2 class="inline-flex text-18 sm:text-22 text-black font-bold mb-6">Calculator for scaled sizes</h2>
  <div class="flex flex-col sm:flex-row gap-8">
    <div class="w-full sm:w-auto flex flex-col gap-3 items-start">
      <h3 class="block text-16 sm:text-18 text-black font-bold mb-4">Scaled spacing</h3>
      <label for="scaled-spacing">Enter the required spacing:</label>
      <div class="flex flex-row items-center w-64 gap-1">
        <input type="number" id="scaled-spacing" name="scaled-spacing" min="0" class="text-right decoration-none"/>
        <span class="font-700">px</span>
      </div>
      <button (click)="calculateScaledSpacing()" class="btn-primary">
        <span class="btn-body">
          Calculate
        </span>
      </button>
      <span *ngIf="scaledSpacingError" class="text-status-error">
        Input size must be divisible with 4
      </span>
    </div>
    <div class="w-full sm:w-18/24">
      <div *ngIf="scaledSpacingSubmitted" class="w-12/24 sm:pr-4">
        <span>
          Values to be added to <code class="inline text-14">tailwind.config.ts</code>:
        </span>
        <div
          class="flex flex-col p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 text-14 rounded w-full overflow-auto">
          <span>'{{ scaledWidthClass }}': '{{scaledWidthValue}}'</span>
          <span>'{{ scaledHeightClass }}': '{{ scaledHeightValue }}'</span>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full border-b border-black my-6"></div>

  <div class="flex flex-col sm:flex-row gap-8">
    <div class="w-full sm:w-auto flex flex-col gap-3 items-start">
      <h3 class="block text-16 sm:text-18 text-black font-bold mb-4">Scaled fonts</h3>
      <label for="scaled-font">Enter the required font size:</label>
      <div class="flex flex-row items-center w-64 gap-1">
        <input type="number" id="scaled-font" name="scaled-font" min="0" class="text-right decoration-none"/>
        <span class="font-700">px</span>
      </div>
      <button (click)="calculateScaledFont()" class="btn-primary">
        <span class="btn-body">
          Calculate
        </span>
      </button>
      <span *ngIf="scaledFontError" class="text-status-error">
        Input size must be divisible with 2
      </span>
    </div>
    <div class="w-full sm:w-18/24">
      <div *ngIf="scaledFontSubmitted" class="flex flex-col sm:flex-row">
        <div class="w-full sm:w-12/24 sm:pr-4 flex flex-col">
          <span>Name of the class and</span>
          <span>
            Value to be added to <code class="inline text-14">scaled-font-sizes.scss</code>:
          </span>
          <div
            class="flex flex-col p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 text-14 rounded w-full overflow-auto">
            <span>{{ scaledFontClass }}</span>
            <span>{{ scaledFontValue }} </span>
          </div>
          <span class="text-14 font-300">
            *The min size is arbitrary, adjust it to your liking but keep it above 12
          </span>
        </div>
        <div class="w-full sm:w-12/24 flex flex-col">
          <span>Value of the compiled class</span>
          <span>&nbsp;</span>
          <div class="flex flex-col p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 rounded w-full overflow-auto">
              <pre class="text-12">{{ scaledFontCompiledValue }}</pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
