<div class="flex flex-col sm:flex-row">
  <div class="w-full sm:w-6/24">
    <h2 *ngIf="contentModule.title" class="text-left font-800 text-24 text-scale-24">{{ contentModule.title }}</h2>
  </div>
  <div class="w-full sm:mx-1/24 font-300 text-16 sm:w-11/24">
    <div *ngIf="contentModule.firstDescription"
         class="text-left text-16 text-scale-24 text-black mt-6 sm:mt-0"
         [innerHtml]="contentModule.firstDescription">
    </div>
  </div>
  <div class="w-full sm:w-5/24 mt-6 sm:mt-0">
    <p *ngIf="contentModule.secondDescription"
         class="text-left text-gray-500 text-16 text-scale-24  font-300 leading-140"
         [innerHtml]="contentModule.secondDescription">
    </p>
  </div>
</div>
