<div class="w-full pt-10 pb-8 sm:py-scale-h-26 border-b border-gray-500">
  <div class="container">
    <h3 class="font-abril-bold text-white text-30 text-scale-68 leading-111">
      Our products
    </h3>
  </div>
</div>
<div class="container pt-10 pb-13 sm:pt-scale-h-18 sm:pb-scale-h-22">
  <p class="w-full sm:w-18/24 text-24 text-scale-56 leading-125 sm:leading-122 text-white font-300">
    With a wealth of experience in designing and producing bespoke solutions for our clients, we have evolved to craft our own products. Years of expertise have fueled our journey, that reflect our steadfast commitment to excellence and innovative thinking.
  </p>
</div>
