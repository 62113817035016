import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-module-a120',
  templateUrl: './module-a120.component.html',
  styleUrls: ['./module-a120.component.scss']
})
export class ModuleA120Component {

  @Input() contentModule: any;

}
