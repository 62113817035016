import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-module-a010',
  templateUrl: './module-a010.component.html'
})
export class ModuleA010Component {

  @Input() contentModule: any;

}
