<div class="w-full h-screen relative flex flex-col">
  <div class="container flex-1 flex items-end sm:items-center">
    <h1 class="animated-text--on-dark text-48 text-scale-130 leading-105 sm:leading-[0.85] font-900 uppercase">
      <span class="animated-text-row">Featured</span>
      <span class="animated-text-row">projects</span>
    </h1>
  </div>
  <div class="container pt-4">
    <span class="text-18 text-scale-22 font-300 text-white">
      Trusted by leading organisations.
    </span>
  </div>
  <div class="pt-10 sm:pt-scale-h-14 pb-13 sm:pb-scale-h-13">
    <app-logo-slider [onDark]="true"
                     [slidesPerViewDesktop]="9"
                     [slidesPerViewMobile]="4"
                     [priority]="true">
    </app-logo-slider>
    <div class="pt-6 sm:pt-scale-h-6"></div>
    <app-logo-slider [onDark]="true"
                     [reverse]="true"
                     [slidesPerViewDesktop]="9"
                     [slidesPerViewMobile]="4"
                     [priority]="true">
    </app-logo-slider>
  </div>

  <div *ngIf="platformService.isBrowser()" class="curtain-element"></div>
</div>
