<div class="w-full grid grid-cols-1 sm:grid-cols-2 gap-scale-w-6">
  <div class="w-full h-full sm:relative">
    <div class="w-full bg-gray-300 h-[608px] sm:h-[145vh] rounded-10 relative overflow-hidden sm:sticky sm:top-[-12vh]">
      <picture *ngIf="contentModule.imageDesktop && contentModule.imageMobile">
        <source media="(min-width:768px)" [srcset]="contentModule.imageDesktop">
        <source media="(max-width:768px)" [srcset]="contentModule.imageMobile">
        <img [src]="contentModule.imageDesktop" alt="Fleka" class="absolute inset-0 object-cover object-center w-full h-full">
      </picture>
    </div>
  </div>

  <div class="w-full flex flex-col">
    <div class="bg-gray-300 h-100 sm:h-[95vh] rounded-10 relative overflow-hidden order-2 sm:order-1 mt-10 sm:mt-0">
      <video *ngIf="contentModule.videoSrc"
             [autoplay]="true"
             [muted]="true"
             class="absolute inset-0 object-cover object-center w-full h-full">
        <source [src]="contentModule.videoSrc">
      </video>
    </div>
    <div class="text-black mt-10 sm:mt-scale-h-20 sm:pl-scale-w-18 sm:pr-scale-w-10 order-1 sm:order-2">
      <h2 *ngIf="contentModule.title" class="text-30 text-scale-72 leading-111 font-abril-bold">
        {{ contentModule.title}}
      </h2>
      <p *ngIf="contentModule.description"
         class="text-16 text-scale-24 leading-140 mt-6 sm:mt-scale-h-12"
         [innerHtml]="contentModule.description">
      </p>
    </div>
  </div>
</div>
