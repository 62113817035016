import { Component } from '@angular/core';
import { Project } from '../../../project/models/project.model';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-work-project-list',
  templateUrl: './work-project-list.component.html'
})
export class WorkProjectListComponent {

  projects: Project[] = [
    {
      title: 'Website for the Government of Montenegro',
      url: '#',
      video_path_webm: environment.s3Repository + '/videos/projects/gov/gov_square.webm',
      video_path_mp4: environment.s3Repository + '/videos/projects/gov/gov_square.mp4',
      thumbnail: environment.s3Repository + '/videos/projects/gov/gov_square.jpg',
    },
    {
      title: 'Online banking (Web/App) and website for Lovćen banka',
      url: '#',
      video_path_webm: environment.s3Repository + '/videos/projects/lovcen/lovcen_square.webm',
      video_path_mp4: environment.s3Repository + '/videos/projects/lovcen/lovcen_square.mp4',
      thumbnail: environment.s3Repository + '/videos/projects/lovcen/lovcen_square.jpg',
    },
    {
      title: 'UX/UI design, Product development for web and mobile app for Cosmetics',
      url: '#',
      video_path_webm: environment.s3Repository + '/videos/projects/cosmetics/cosmetics_square.webm',
      video_path_mp4: environment.s3Repository + '/videos/projects/cosmetics/cosmetics_square.mp4',
      thumbnail: environment.s3Repository + '/videos/projects/cosmetics/cosmetics_square.jpg',
    },
    {
      title: 'Digital suite of products - Web & Mobile apps for DokTok, telemedicine platform',
      url: '#',
      video_path_webm: environment.s3Repository + '/videos/projects/doktok/doktok_square.webm',
      video_path_mp4: environment.s3Repository + '/videos/projects/doktok/doktok_square.mp4',
      thumbnail: environment.s3Repository + '/videos/projects/doktok/doktok_square.jpg',
    },
    {
      title: 'Government Business portal for small and medium enterprises',
      url: '#',
      video_path_webm: environment.s3Repository + '/videos/projects/bizgov/bizgov_square.webm',
      video_path_mp4: environment.s3Repository + '/videos/projects/bizgov/bizgov_square.mp4',
      thumbnail: environment.s3Repository + '/videos/projects/bizgov/bizgov_square.jpg',
    },
    {
      title: 'Montenegrin Music Center website with online booking system',
      url: '#',
      video_path_webm: environment.s3Repository + '/videos/projects/mc/mc_square.webm',
      video_path_mp4: environment.s3Repository + '/videos/projects/mc/mc_square.mp4',
      thumbnail: environment.s3Repository + '/videos/projects/mc/mc_square.jpg',
    }
  ];

  faArrowRight = faArrowRight;
}
