import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlTree } from '@angular/router';
import { AppLayoutComponent } from './@shared/layouts/app-layout/app-layout.component';
import { RouteName } from './Library/routes';
import { HomepageLayoutComponent } from './@modules/homepage/components/homepage-layout/homepage-layout.component';
import { seoRulesHomepage } from './Library/seo-rules/seo-rules-homepage';
import { environment } from '../environments/environment';

const routes: Routes = [
  {
    path: 'design-system',
    canActivate: [() =>  !environment.staging && !environment.production ? true : new UrlTree()],
    loadChildren: () => import('./@modules/design-system/design-system.module').then(m => m.DesignSystemModule)
  },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: HomepageLayoutComponent,
        data: {
          routeName: RouteName.HOME,
          seoItem : seoRulesHomepage['en']
        }
      },
      {
        path: 'work',
        loadChildren: () => import('./@modules/work/work.module').then(m => m.WorkModule)
      },
      {
        path: 'about',
        loadChildren: () => import('./@modules/about/about.module').then(m => m.AboutModule)
      },
      {
        path: 'contact',
        loadChildren: () => import('./@modules/contact/contact.module').then(m => m.ContactModule)
      },
      {
        path: 'careers',
        loadChildren: () => import('./@modules/careers/careers.module').then(m => m.CareersModule)
      },
      {
        path: '**',
        loadChildren: () => import('./@modules/not-found/not-found.module').then(m => m.NotFoundModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    onSameUrlNavigation: 'reload'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
