<div class="relative w-full h-screen bg-gray-100 flex flex-col justify-end pb-30 sm:pb-scale-h-42">
  <div class="container text-48 text-scale-120 leading-105 sm:leading-[0.95] font-900 uppercase">
    <h1 *ngIf="!platformService.isMobile()" class="animated-text">
      <span class="animated-text-row">Oops...</span>
      <span class="animated-text-row">Page not found</span>
    </h1>
    <h1 *ngIf="platformService.isMobile()" class="animated-text">
      <span class="animated-text-row">Oops...</span>
      <span class="animated-text-row">Page not</span>
      <span class="animated-text-row">found</span>
    </h1>
  </div>

  <div class="w-full pt-12 sm:pt-scale-h-18 font-inter">
    <div class="container">
      <div class="w-20/24 sm:w-8/24">
        <div class="w-full flex flex-col">
          <p class="text-black text-16 text-scale-24 leading-137 sm:leading-150 font-300">
            The page you’re looking for can’t be found.You may have used an outdated link or may have typed the address (URL) incorrectly.
          </p>
          <div class="self-start relative z-0">
            <a [routerLink]="['/']"
               title="To Homepage"
               class="btn btn-secondary group mt-10 sm:mt-scale-h-10 self-start">
              <span class="btn-body text-14 text-scale-20">Take me home</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="platformService.isBrowser()" class="curtain-element"></div>
</div>

