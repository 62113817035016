import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-design-system',
  templateUrl: './design-system.component.html',
  styleUrls: ['./design-system.component.scss']
})
export class DesignSystemComponent implements OnInit {
  sections = [
    { value: 0, label: 'All components' },
    { value: 1, label: 'Scalable sizes' },
    { value: 2, label: 'Buttons' },
    { value: 3, label: 'Modules'}
  ];
  activeSection: number = 0;
  constructor() { }

  ngOnInit(): void {
  }

  selectSection(section): void {
    this.activeSection = section;
  }

}
