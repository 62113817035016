<div class="parallax-wrapper">

  <div class="parallax-element bg-gray-100">
    <app-about-hero></app-about-hero>
  </div>

  <div class="parallax-static-background-element">
    <app-video-module [video_id]="903402496">
    </app-video-module>
  </div>

  <div class="parallax-element bg-black">
    <app-about-team></app-about-team>
  </div>

  <div *ngIf="jobs" class="parallax-element bg-gray-100">
    <app-about-jobs [jobs]="jobs"></app-about-jobs>
  </div>

  <div class="parallax-element bg-black">
    <app-cta-module></app-cta-module>
  </div>

  <div class="parallax-element bg-white">
    <app-footer></app-footer>
  </div>

</div>
