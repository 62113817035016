import { Injectable } from '@angular/core';
import { Contact } from '../models/contact.model';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  contactDetails: {} = {
    email: new Contact({
      label: 'hello@fleka.me',
      href: 'mailto:hello@fleka.me'
    }),
    tel: new Contact({
      label: '+382.20.238.760',
      href: 'tel:+38220238760'
    }),
    instagram: new Contact({
      label: 'Instagram',
      href: 'https://instagram.com/flekadesign',
    }),
    facebook: new Contact({
      label: 'Facebook',
      href: 'https://www.facebook.com/flekadesign',
    }),
    linkedin: new Contact({
      label: 'LinkedIn',
      href: 'https://www.linkedin.com/company/fleka',
    }),
    address: new Contact({
      label: [
        'Ulica Vasa Raičkovića 13/1',
        '81000 Podgorica',
        'Montenegro'
      ],
      href: 'https://g.page/flekadesign'
    })
  };

  public getContactDetails() {
    return this.contactDetails;
  }
}
