import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-ds-scalable-sizes',
  templateUrl: './ds-scalable-sizes.component.html'
})
export class DsScalableSizesComponent implements OnInit {

  scaledWidthClass: string;
  scaledWidthValue: string;

  scaledHeightClass: string;
  scaledHeightValue: string;

  scaledSpacingError: boolean = false;
  scaledSpacingSubmitted: boolean = false;

  scaledFontClass: string;
  scaledFontValue: string;
  scaledFontCompiledValue: string;

  scaledFontSubmitted: boolean = false;
  scaledFontError: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  calculateScaledSpacing(): void {
    this.scaledSpacingSubmitted = true;
    this.scaledSpacingError = false;

    const inputValue: number = Number((<HTMLInputElement>document.getElementById('scaled-spacing')).value);

    if (inputValue === 0 || inputValue % 4 !== 0) {
      this.scaledSpacingError = true;
      this.scaledSpacingSubmitted = false;
    }

    this.scaledWidthClass = 'scale-w-' + (inputValue / 4);
    this.scaledHeightClass = 'scale-h-' + (inputValue / 4);

    this.scaledWidthValue = (inputValue / 1920 * 100).toFixed(2) + 'vw';
    this.scaledHeightValue = (inputValue / 1080 * 100).toFixed(2) + 'vh';
  }

  calculateScaledFont(): void {
    this.scaledFontSubmitted = true;
    this.scaledFontError = false;

    const inputValue: number = Number((<HTMLInputElement>document.getElementById('scaled-font')).value);

    if (inputValue === 0 || inputValue % 2 !== 0) {
      this.scaledFontError = true;
      this.scaledFontSubmitted = false;
    }

    let minValue: number = Math.max(12, Math.ceil(inputValue * 4 / 6));
    if (minValue % 2 !== 0) {
      minValue++;
    }

    this.scaledFontClass = 'text-scale-' + inputValue;
    this.scaledFontValue = '(' + minValue + ' ' + inputValue + ')';
    this.scaledFontCompiledValue = `.text-scale-` + inputValue + ` {
  font-size: ` + inputValue + `px;
  font-size: clamp(` + minValue + `px,` + (inputValue / 1920 * 100).toFixed(10) + `vw,` + inputValue + `px);
}`;
  }

}
