import { Component, Input } from '@angular/core';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { Job } from '../../../jobs/models/job.model';
import { RouteName } from '../../../../Library/routes';
import { TypeformService } from '../../../../Library/services/typeform.service';

@Component({
  selector: 'app-about-jobs',
  templateUrl: './about-jobs.component.html'
})
export class AboutJobsComponent {

  @Input() jobs: Job[];

  careersRoute = RouteName.CAREERS;

  faArrowRight = faArrowRight;

  constructor(protected typeformService: TypeformService) {
  }
}
