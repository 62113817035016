import { Component } from '@angular/core';
import { PlatformService } from '../../../platform/services/platform.service';

@Component({
  selector: 'app-about-hero',
  templateUrl: './about-hero.component.html'
})
export class AboutHeroComponent {
  constructor(protected platformService: PlatformService) {
  }
}
