<div class="min-h-screen container pt-9 sm:pt-scale-h-18 pb-38 sm:pb-scale-h-40 flex flex-col">
  <span class="text-16 text-scale-24 text-white uppercase">
    #OUR<span class="font-700">PRODUCTS</span>
  </span>
  <div class="pt-8 sm:pt-scale-h-18 flex-1 flex flex-col-reverse sm:flex-row sm:items-center gap-y-9">
    <div class="w-full sm:w-7/24 sm:mr-1/24 flex flex-col items-start text-white">
      <h4 class="text-36 text-scale-66 font-700">
        PrestoLink
      </h4>
      <span class="mt-3 sm:mt-scale-h-6 text-24 text-scale-44 leading-120 font-300">
        Offer your customers a faster checkout experience with our one-click payment solution – no online store needed.
      </span>

      <div class="mt-6 sm:mt-scale-h-9 relative z-0">
        <a href="https://www.prestopay.me/link"
           title="PrestoLink"
           target="_blank"
           class="btn btn-secondary--on--dark group !bg-p-300 sm:hover:!bg-white">
          <span class="btn-body">Learn more</span>
          <span
            class="btn-arrow-wrapper--on--dark opacity-0 sm:group-hover:opacity-100 !transition-[right,background-color,transform,opacity] !duration-500">
            <fa-icon class="btn-arrow-icon"
              [icon]="faArrowRight">
            </fa-icon>
          </span>
        </a>
      </div>
    </div>
    <div class="w-full sm:w-16/24 flex items-center justify-between">
      <div class="z-back w-20/24 sm:w-22/24 h-76 sm:h-full rounded-2 sm:rounded-3 overflow-hidden relative">
        <img ngSrc="assets/img/homepage/prestolink/prestolink_image.jpg" alt="PrestoLink hero image" width="1551" height="1118"
             class="h-full object-cover object-center"/>
      </div>
      <div class="w-12/24 sm:w-9/24 -ml-8/24 flex flex-col items-end gap-1 sm:gap-scale-h-3">
        <img ngSrc="../../../../../assets/img/homepage/prestolink/prestolink_gfx1.jpg"
             width="683" height="624" alt="PrestoLink graphics" class="w-full rounded sm:rounded-3"/>
        <img ngSrc="../../../../../assets/img/homepage/prestolink/prestolink_gfx2.jpg"
             width="393" height="245" alt="PrestoLink graphics" class="w-14/24 rounded sm:rounded-3"/>
      </div>
    </div>
  </div>
</div>
