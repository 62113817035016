import { Component } from '@angular/core';
import { moduleDescriptions } from '../../module-descriptions';

@Component({
  selector: 'app-ds-modules',
  templateUrl: './ds-modules.component.html',
  styleUrls: ['./ds-modules.component.scss']
})
export class DsModulesComponent {

  contentModules: any = [
    {
      title: 'Digitization of the largest loyalty program in our country ',
      imageDesktop: 'https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis01Desktop.jpg',
      imageMobile: 'https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis01Desktop.jpg',
      videoSrc: 'https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/video/cosmetics-hero-video.mp4',
      description: `Cosmetics spent two decades earning a devoted following, all without any advertising. The Montenegro-based
              company was worth 50 million euros, but they still hadn’t translated their ethos of “unselling” online.
              They asked Fleka to design, build, and ship a new e-commerce site and native applications. <br><br>
              Working together, we created an immersive digital storefront inspired by the in-store experience.
              Our relationship grew into broad customer-experience strategy, including in-store and sales associate
              touchpoints, content integration, and a new loyalty program.`
    },
    {
      order: 1,
      title: 'Bringing the in-store experience online',
      description: 'Ako znamo da brojke govore više od riječi, jasno je koliko je odgovoran i izazovan zadatak bio ' +
        'kreirati potpuno novo korisničko iskustvo za više od 140.000 članova Beauty Friend programa ' +
        'lojalnosti. <br><br>Svaki kupac želi da ostvari popust i dobije više za svoj novac. Ali, previše plastike u novčaniku mnoge odbija od pristupanja nekom od mnoštva dostupnih programa lojalnosti na našem tržištu. Sa tom mišlju i puno pasije, ušli smo u projekat digitalizacije jedne od najpoznatijih loyalty kartica, koja na crnogorskom tržištu postoji više od 10 godina - Beauty Friend kartice.'
    },
    {
      title: 'Digital extension',
      firstDescription: moduleDescriptions[2],
      secondDescription: moduleDescriptions[3]
    },
    {
      title: 'Deep analysis',
      description: moduleDescriptions[4]
    },
    {
      imageDesktop: 'https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis02Desktop.jpg',
      imageMobile: 'https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/mobile/cosmetis02Mobile.jpg'
    },
    {
      firstImageDesktop: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis03Desktop.jpg`,
      firstImageMobile: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/mobile/cosmetis04Mobile.jpg`,
      secondImageDesktop: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis05Desktop.jpg`,
      secondImageMobile: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/mobile/cosmetis05Mobile.jpg`
    },
    {
      imageDesktop: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis03Desktop.jpg`,
      imageMobile: `https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/mobile/cosmetis03Mobile.jpg`
    },
    {
      quote: `“It was a highly collaborative process. They took the time to become well-versed in the intricacies of our
      business, and instead of big reveals at set milestones, we were in constant communication throughout the process.
        That enabled us to experiment with a wide variety of ideas and to incorporate findings quickly.”`,
      author: 'Tomislav Đorojević',
      position: 'Founder and CEO at MPM Cosmetics'
    },
    {
      title: 'Outcomes:',
      values: ['17%', '4x', '4x', '60'],
      descriptions:
        ['Lift in brand perception in the first quarter of launch',
          'Lift in brand perception in the first quarter of launch',
          'Growth in e-commerce in 3 years',
          'Growth in e-commerce in 3 years']
    },
    {
      title: 'What we did',
      listTitle: 'Highlights:',
      list: ['Vision for transformation',
        'Product strategy and roadmapping',
        'Design and art direction',
        'Content strategy',
        'Solution arcitecture',
        'User testing',
        'Training for Cosmetics business developers during handoff']
    },
    {
      title: 'Cosmetics',
      description: 'Building a digital-first customer experience',
      imageDesktop: 'https://plus.unsplash.com/premium_photo-1664053453717-6fbe70ea92ed?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
      imageMobile: 'https://plus.unsplash.com/premium_photo-1664053453717-6fbe70ea92ed?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
    },
    {
      title: `Montenegrin Music Center`,
      description: 'Building a digital-first customer experience',
      imageDesktop: 'https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis10Desktop.jpg',
      imageMobile: 'https://s3.eu-central-1.amazonaws.com/web.repository/fleka-website/project-assets/cosmetics/desktop/cosmetis10Desktop.jpg'
    },
    {
      title: 'Cosmetics',
      firstSubtitle: 'About project',
      secondSubtitle: 'Industry',
      firstDescription: moduleDescriptions[5],
      secondDescription: moduleDescriptions[6]
    },
    {
      title: '4 Big Takeaways',
      listTitle: 'Table Of Contents:',
      list: [
        'Bringing the in-store experience online',
        'Data-driven design',
        'Unique design frameworks',
        'Vision for transformation',
        'Vision for transformation'
      ]
    }
  ];

  moduleA010: string = `<app-module-a010
    [contentModule]="contentModules[0]"
></app-module-a010>`;

  moduleA020: string = `<app-module-a020
        [contentModule]="contentModules[2]"
      ></app-module-a020>`;

  moduleA030: string = ` <app-module-a030
    [contentModule] = "contentModules[2]">
    </app-module-a030>`;

  moduleA040: string = `<app-module-a040
        [contentModule]="contentModules[3]"
      ></app-module-a040>`;

  moduleA050: string = `<app-module-a050
    [contentModule] = "contentModules[4]" >
    </app-module-a050>`;

  moduleA060: string = `<app-module-a060 [contentModule]="contentModules[5]"></app-module-a060>`;

  moduleA070: string = `<app-module-a070 [contentModule]="contentModules[6]"></app-module-a070>`;

  moduleA080: string = ` <app-module-a080
        [contentModule]="contentModules[7]"
      ></app-module-a080>`;

  moduleA090: string = `<app-module-a090
        [contentModule]="contentModules[8]"
      ></app-module-a090>`;

  moduleA100: string = `<app-module-a100
     [contentModule]="contentModules[9]"
    ></app-module-a100>`;

  moduleA110: string = ` <app-module-a110
        [contentModule]="contentModules[10]"
      ></app-module-a110>`;

  moduleA120: string = `<app-module-a120
        [contentModule]="contentModules[11]"
      ></app-module-a120>`;

  moduleA130: string = `<app-module-a130
       [contentModule]="contentModules[12]"
      ></app-module-a130>`;

  moduleA140: string = `<app-module-a140
      [contentModule]="contentModules[13]"
    ></app-module-a140>`;

  moduleDescriptions: string[] = moduleDescriptions;

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
  }

}
