import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-module-a090',
  templateUrl: './module-a090.component.html',
  styleUrls: ['./module-a090.component.scss']
})
export class ModuleA090Component  {

  @Input() contentModule: any;

}
