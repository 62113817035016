import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PlatformService } from '../../@modules/platform/services/platform.service';

@Injectable({
  providedIn: 'root'
})
export class MutationService {
  private mutationObserver: MutationObserver;

  public domChange: BehaviorSubject<HTMLElement>;

  constructor(private platformService: PlatformService) {
    if (this.platformService.isBrowser()) {
      this.domChange = new BehaviorSubject<HTMLElement>(document.documentElement);
    } else {
      this.domChange = new BehaviorSubject(null);
    }
  }

  public createMutationObserver(): void {
    if (!this.platformService.isBrowser()) {
      return;
    }

    if (this.mutationObserver) {
      delete this.mutationObserver;
    }

    this.mutationObserver = new MutationObserver(this.handleDOMMutations);

    this.mutationObserver.observe(document.querySelector('main'), {
      childList: true
    });
  }

  private handleDOMMutations = (mutations: MutationRecord[]): void => {
    const latestChange: HTMLElement = mutations[mutations.length - 1].target as HTMLElement;

    this.domChange.next(latestChange);
  };
}
