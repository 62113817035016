import { Injectable } from '@angular/core';
import { SeoItem } from 'src/app/Library/services/seo/seo-item.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';
import { HasSeoItem } from '../../../Library/services/seo/has-seo-item';
import { DateTime, DateTimeMaybeValid } from 'luxon';
import { environment } from '../../../../environments/environment';
import { RouteName } from '../../../Library/routes';


export class Job implements HasSeoItem {

  id?: number;
  title?: string;
  slug?: string;
  introduction?: string;
  description?: string;
  submit_deadline?: string;
  submitDeadline?: DateTimeMaybeValid;
  typeform_parameter?: string;

  // SEO
  seo_title?: string;
  seo_description?: string;
  seo_keywords?: string;


  constructor(data?: {}) {
    Object.assign(this, data);
  }

  getSeoItem(): SeoItem {
    return new SeoItem({
      title: this.seo_title ? this.seo_title : this.title,
      description: this.seo_description ?? this.introduction,
      keywords: this.seo_keywords,
      seo_fb_type: 'website',
      seo_tw_type: 'website',
      seo_fb_image_url: environment.websiteUrl + '/assets/img/seo/seo_1200x630.jpg',
      seo_tw_image_url: environment.websiteUrl + '/assets/img/seo/seo_1200x675.jpg',
      canonical_url: environment.websiteUrl + '/' + RouteName.CAREERS + '/' + this.slug
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class JobAdapter extends BaseAdapter<Job> {

  constructor() {
    super(Job);
  }

  adapt(data: any): Job {

    const item: Job = super.adapt(data);

    if (item.submit_deadline) {
      item.submitDeadline = DateTime.fromSQL(item.submit_deadline, {zone: 'UTC'});
    }

    return item;
  }
}
