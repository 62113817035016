<div class="w-full pt-18 sm:pt-scale-h-26 pb-10 sm:pb-scale-h-27">
  <div class="w-full mb-9 sm:mb-scale-h-14">
    <div class="container">
      <div class="w-full sm:w-16/24 mb-25 sm:mb-scale-h-24">
        <p class="text-white font-300 text-24 text-scale-56 leading-125">
          Our team excels in transforming concepts into vibrant digital experiences. With a blend of expertise in design and development, we're dedicated to elevating the impact and reach of your business. Focusing on innovation and user engagement, we ensure each project is not just a creation, but a journey towards realizing your vision.
        </p>
      </div>
      <h3 class="text-white text-16 text-scale-24">
        Our services:
      </h3>
    </div>
  </div>
  <div *ngFor="let service of services, let index = index"
       (click)="toggleService(index)"
       class="group w-full border-t border-gray-500 py-5 sm:py-scale-h-12 text-white hover:cursor-pointer">
    <div class="inner container">
      <div class="flex items-center sm:items-start flex-wrap gap-y-5">
        <div class="w-16/24 sm:w-7/24 order-1">
          <span class="text-20 text-scale-38 font-700 leading-120">
            {{ service.title }}
          </span>
        </div>
        <div class="wrapper w-full sm:w-9/24 sm:ml-6/24 order-3 sm:order-2"
             [ngStyle]="{'transition-duration': transitionDurationInMilliseconds + 'ms' }"
             [ngClass]="indexOfOpenedService === index ? 'is-open' : ''">
          <div class="inner relative">
            <span class="text-14 text-scale-24 leading-144 font-300">
              {{ service.description }}
            </span>
            <div
              class="absolute inset-x-0 bottom-0 h-8 bg-gradient-to-t from-black via-black to-transparent transition-opacity"
              [ngStyle]="{'transition-duration': transitionDurationInMilliseconds + 'ms' }"
              [ngClass]="indexOfOpenedService === index ? 'opacity-0 ease-sharp-out' : 'opacity-100 ease-sharp-in'"></div>
          </div>
        </div>
        <div class="w-4/24 sm:w-2/24 ml-4/24 sm:ml-0 order-2 sm:order-3 flex justify-end">
          <div class="relative uppercase text-14 text-scale-24">
            <span *ngIf="indexOfOpenedService === index">
              Hide
            </span>
            <span *ngIf="indexOfOpenedService !== index">
              Show
            </span>
            <app-animated-underline [origin]="'right'" [defaultVisible]="true"></app-animated-underline>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
