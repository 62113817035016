<div class="w-full h-full relative pointer-events-none">
  <video loop
         muted
         playsinline
         width="100%"
         height="auto"
         class="w-full h-full object-cover object-center"
         [poster]="project.thumbnail"
  >
    <source [src]="project.video_path_webm" type="video/webm">
    <source [src]="project.video_path_mp4" type="video/mp4">
  </video>
</div>
