import { AfterViewInit, Component, Inject, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../../Library/compontents/base.component';
import { SeoService } from '../../../../Library/services/seo/seo.service';
import { TypographyService } from '../../../../Library/services/typography.service';
import { PlatformService } from '../../../platform/services/platform.service';

@Component({
  selector: 'app-contact-layout',
  templateUrl: './contact-layout.component.html'
})
export class ContactLayoutComponent extends BaseComponent implements AfterViewInit {

  constructor(
    private typographyService: TypographyService,
    protected platformService: PlatformService,
    protected route: ActivatedRoute,
    protected seoService: SeoService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) public platformId: any
  ) {
    super(route, seoService, locale, platformId);
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.typographyService.init();
  }
}
