import { AfterViewInit, Component, Inject, LOCALE_ID, OnDestroy, PLATFORM_ID } from '@angular/core';
import { BaseComponent } from 'src/app/Library/compontents/base.component';
import { ServerResponseService } from '../../../../Library/server-response.service';
import { TypographyService } from '../../../../Library/services/typography.service';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from '../../../../Library/services/seo/seo.service';
import { PlatformService } from '../../../platform/services/platform.service';
import { seoRulesNotFound } from '../../../../Library/seo-rules/seo-rules-not-found';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent extends BaseComponent implements AfterViewInit, OnDestroy {
  constructor(
    protected route: ActivatedRoute,
    protected seoService: SeoService,
    protected platformService: PlatformService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) public platformId: any,
    srs: ServerResponseService,
    private typographyService: TypographyService
  ) {
    super(route, seoService, locale, platformId);
    srs.setNotFound();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.seoService.refresh(seoRulesNotFound['en']);
    this.typographyService.init();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
