<div class="w-full bg-black flex flex-col rounded-t-5 text-white">
  <div class="w-full py-8 sm:py-scale-h-14 border-b border-gray-500 sm:border-gray-700">
    <div class="container text-left">
      <h2 *ngIf="contentModule.title" class="font-abril-bold text-30 text-scale-68 leading-120">{{ contentModule.title }}</h2>
    </div>
  </div>
  <div class="w-full py-10 sm:py-scale-h-18">
    <div class="container flex flex-col sm:flex-row">
      <div class="w-full sm:w-6/24">
        <span *ngIf="contentModule.listTitle"
              class="font-700 text-24 text-scale-24 leading-120">
          {{ contentModule.listTitle }}
        </span>
      </div>
      <div class="w-full sm:w-18/24 mt-10 sm:mt-0">
        <ul class="space-y-8 sm:space-y-scale-h-16" style="list-style-type: disc;">
          <li *ngFor="let listItem of contentModule.list"
              class="font-300 text-18 text-scale-32 leading-120">
            {{ listItem }}
          </li>
        </ul>
      </div>
    </div>
  </div>

</div>
