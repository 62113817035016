import { AfterViewInit, Component, Inject, LOCALE_ID, OnDestroy, PLATFORM_ID } from '@angular/core';
import { TypographyService } from '../../../../Library/services/typography.service';
import { BaseComponent } from '../../../../Library/compontents/base.component';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from '../../../../Library/services/seo/seo.service';

@Component({
  selector: 'app-work-layout',
  templateUrl: './work-layout.component.html'
})
export class WorkLayoutComponent extends BaseComponent implements AfterViewInit, OnDestroy {

  constructor(
    private typographyService: TypographyService,
    protected route: ActivatedRoute,
    protected seoService: SeoService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) public platformId: any
  ) {
    super(route, seoService, locale, platformId);
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.typographyService.init();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
