import { Injectable } from '@angular/core';
import { Job, JobAdapter } from '../models/job.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ResourceService } from '../../../Library/resource/resource.service';

@Injectable({
  providedIn: 'root'
})
export class JobService extends ResourceService<Job> {

  url: string = environment.apiUrl + '/jobs';

  constructor(
    httpClient: HttpClient,
    adapter: JobAdapter
  ) {
    super('/jobs', httpClient, adapter);
  }

}
