import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { SeoItem } from './seo-item.model';
import { RouteName } from '../../routes';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { LocaleService } from '../locale.service';

@Injectable({
  providedIn: 'root'
})
export class SeoRichDataService {

  private defaultText: string = '';
  private currentRoute: RouteName = null;
  private currentObject: any = null;
  private seoItem: SeoItem = null;
  private link: HTMLLinkElement;
  private ouTitle: string;

  constructor(
    private localeService: LocaleService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private locale: string
  ) {
    const logoData: any = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: environment.websiteUrl + '/assets/img/logos/logo.png'
    };

    const structuredDataScript = this.document.createElement('script');
    structuredDataScript.setAttribute('type', 'application/ld+json');
    structuredDataScript.setAttribute('id', 'logo');
    structuredDataScript.innerHTML = JSON.stringify(logoData);
    this.document.head.appendChild(structuredDataScript);
  }

  setCurrentRoute(currentRoute: RouteName): void {
    this.currentRoute = currentRoute;
  }

  setDefaultText(defaultText: string): void {
    this.defaultText = defaultText;
  }

  setCurrentObject(currentObject): void {
    this.currentObject = currentObject;
  }

  setSeoItem(seoItem: SeoItem): void {
    this.seoItem = seoItem;
  }

  reset(): void {
    this.setCurrentObject(null);
    this.setSeoItem(null);
    this.setCurrentRoute(null);
    this.removeRichData();
  }

  removeRichData(): void {
    const richData = this.document.querySelectorAll('[type="application/ld+json"]');
    richData.forEach((richElement) => {
      if (richElement.getAttribute('id') !== 'logo') {
        richElement.remove();
      }
    });
  }

  refresh(): void {
    this.setBreadcrumbs();
  }

  setBreadcrumbs(): void {
    if (this.currentObject) {
      const breadcrumbData = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: []
      };
      let position = 1;

      breadcrumbData.itemListElement.push({
        '@type': 'ListItem',
        position: position++,
        name: $localize `:@@breadcrumbs_home:Početna`,
        item: this.localeService.getLocaleBaseUrl()
      });

      if (this.currentRoute === RouteName.HOME) {
        breadcrumbData.itemListElement.push({
          '@type': 'ListItem',
          position: position++,
          name: this.currentObject.title,
        });
      }

      breadcrumbData.itemListElement.push({
        '@type': 'ListItem',
        position: position++,
        name: this.currentObject.title
      });

      const breadcrumbScript = this.document.createElement('script');
      breadcrumbScript.setAttribute('type', 'application/ld+json');
      breadcrumbScript.innerHTML = JSON.stringify(breadcrumbData);
      this.document.head.appendChild(breadcrumbScript);
    }
  }
}
