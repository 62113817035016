import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/@shared/shared.module';

import { HomepageLayoutComponent } from './components/homepage-layout/homepage-layout.component';
import { HomepageRoutingModule } from './homepage-routing.module';
import { RouterModule } from '@angular/router';
import { HomepageHeroComponent } from './components/homepage-hero/homepage-hero.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomepageServicesComponent } from './components/homepage-services/homepage-services.component';
import { HomepageFeaturedProjectsComponent } from './components/homepage-featured-projects/homepage-featured-projects.component';
import { HomepageOurClientsComponent } from './components/homepage-our-clients/homepage-our-clients.component';
import { HomepageOurProductsComponent } from './components/homepage-our-products/homepage-our-products.component';
import { HomepageProductSkenComponent } from './components/homepage-product-sken/homepage-product-sken.component';
import { HomepageProductPrestolinkComponent } from './components/homepage-product-prestolink/homepage-product-prestolink.component';
import { HomepageLatestStoriesComponent } from './components/homepage-latest-stories/homepage-latest-stories.component';
import { ProjectModule } from '../project/project.module';
import { AnimatedUnderlineComponent } from '../../@shared/components/animated-underline/animated-underline.component';

@NgModule({
  declarations: [
    HomepageLayoutComponent,
    HomepageHeroComponent,
    HomepageServicesComponent,
    HomepageFeaturedProjectsComponent,
    HomepageOurClientsComponent,
    HomepageOurProductsComponent,
    HomepageProductSkenComponent,
    HomepageProductPrestolinkComponent,
    HomepageLatestStoriesComponent,
  ],
    imports: [
        CommonModule,
        HomepageRoutingModule,
        SharedModule,
        RouterModule,
        FontAwesomeModule,
        NgOptimizedImage,
        ProjectModule,
        AnimatedUnderlineComponent
    ],
  providers: []
})
export class HomepageModule { }
