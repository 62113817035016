import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormControlValidationDirective } from '../Library/form/directives/form-control-validation.directive';
import { FormSubmitValidationDirective } from '../Library/form/directives/form-submit-validation.directive';
import { LoaderComponent } from './components/loader/loader.component';
import { CheckScriptsPipe, SafeHtmlPipe } from './pipes/safe-html';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ImgFallbackDirective } from '../Library/directives/img-falback.directive';
import { HttpClientJsonpModule } from '@angular/common/http';
import { FlHighlightPipe } from './pipes/fl-highligh';
import { MenuComponent } from './components/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { CtaModuleComponent } from './components/cta-module/cta-module.component';
import { LogoSliderComponent } from './components/logo-slider/logo-slider.component';
import { VideoModuleComponent } from './components/video-module/video-module.component';
import { ImageCarouselComponent } from './components/image-carousel/image-carousel.component';
import { AnimatedUnderlineComponent } from './components/animated-underline/animated-underline.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';

@NgModule({
  declarations: [
    LoaderComponent,
    FormControlValidationDirective,
    FormSubmitValidationDirective,
    FlHighlightPipe,
    ImgFallbackDirective,
    SafeHtmlPipe,
    TruncatePipe,
    CheckScriptsPipe,
    MenuComponent,
    FooterComponent,
    CtaModuleComponent,
    LogoSliderComponent,
    VideoModuleComponent,
    ImageCarouselComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        RouterModule,
        HttpClientJsonpModule,
        NgOptimizedImage,
        AnimatedUnderlineComponent,
        MenuItemComponent
    ],
  exports: [
    LoaderComponent,
    FormControlValidationDirective,
    FormSubmitValidationDirective,
    SafeHtmlPipe,
    FlHighlightPipe,
    TruncatePipe,
    CheckScriptsPipe,
    ImgFallbackDirective,
    MenuComponent,
    FooterComponent,
    CtaModuleComponent,
    LogoSliderComponent,
    VideoModuleComponent,
    ImageCarouselComponent
  ],
  providers: []
})
export class SharedModule {
}
