<div class="w-full h-screen relative flex flex-col justify-end pt-64 sm:pt-scale-h-67">
  <div class="container pb-8 sm:pb-scale-h-26">
    <h1 class="animated-text text-48 text-scale-120 leading-105 sm:leading-[0.85] font-900 uppercase">
      <span class="animated-text-row">Let's get to</span>
      <span class="animated-text-row">know each</span>
      <span class="animated-text-row">other</span>
    </h1>
  </div>

  <div class="w-full border-t border-gray-300 pt-7 sm:pt-scale-h-11 font-inter">
    <div class="container flex justify-center sm:justify-end">
      <div class="w-full sm:w-9/24 flex justify-end pb-3 sm:pb-scale-h-18">
        <p class="text-black text-16 text-scale-24 leading-137 sm:leading-150 font-300">
          Great ideas and intriguing suggestions are welcome at any moment! If you feel that we could form a good team together, don't hesitate to reach out.
          <br/>
          <strong class="font-700">Complete the contact form – we look forward to hearing from you!</strong>
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="platformService.isBrowser()" class="curtain-element"></div>
</div>

