import { Component } from '@angular/core';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { PlatformService } from 'src/app/@modules/platform/services/platform.service';
import { TypeformService } from '../../../Library/services/typeform.service';

@Component({
  selector: 'app-cta-module',
  templateUrl: './cta-module.component.html'
})
export class CtaModuleComponent {
  faArrowRight = faArrowRight;


  constructor(
    protected platformService: PlatformService,
    protected typeformService: TypeformService
  ) {
  }
}
