<div class="parallax-wrapper">
  <div class="parallax-element bg-gray-100">
    <app-homepage-hero></app-homepage-hero>
  </div>

  <div class="parallax-element bg-black">
    <app-homepage-services></app-homepage-services>
  </div>

  <div class="parallax-static-background-element">
    <app-video-module [video_id]="896097641"></app-video-module>
  </div>

  <div class="parallax-element bg-gray-100">
    <app-homepage-featured-projects></app-homepage-featured-projects>
  </div>

  <div class="parallax-element bg-s-400">
    <app-homepage-our-products></app-homepage-our-products>
  </div>

  <div class="parallax-element bg-s-350 bg-gradient-to-t from-s-350 to-s-300">
    <app-homepage-product-sken></app-homepage-product-sken>
  </div>

  <div class="parallax-element bg-p-300">
    <app-homepage-product-prestolink></app-homepage-product-prestolink>
  </div>

  <div class="parallax-element bg-gray-100">
    <app-homepage-our-clients></app-homepage-our-clients>
  </div>

  <div class="parallax-element bg-black">
    <app-cta-module></app-cta-module>
  </div>

  <div class="parallax-element bg-white">
    <app-footer></app-footer>
  </div>
</div>
