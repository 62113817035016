import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkLayoutComponent } from './components/work-layout/work-layout.component';
import { WorkRoutingModule } from './work-routing.module';
import { WorkHeroComponent } from './components/work-hero/work-hero.component';
import { SharedModule } from '../../@shared/shared.module';
import { WorkProjectListComponent } from './components/work-project-list/work-project-list.component';
import { WorkProjectCardComponent } from './components/work-project-card/work-project-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CosmeticsComponent } from './components/cosmetics/cosmetics.component';
import { DesignSystemModule } from '../design-system/design-system.module';
import { ProjectModule } from '../project/project.module';

@NgModule({
  declarations: [
    WorkLayoutComponent,
    WorkHeroComponent,
    WorkProjectListComponent,
    WorkProjectCardComponent,
    CosmeticsComponent
  ],
  imports: [
    CommonModule,
    WorkRoutingModule,
    SharedModule,
    FontAwesomeModule,
    DesignSystemModule,
    ProjectModule
  ]
})
export class WorkModule { }
