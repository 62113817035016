import {
  AfterViewInit,
  Component,
  ElementRef,
  Input, OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { BRAND_LOGOS, BrandLogo } from '../../../Library/brand-logos';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { PlatformService } from '../../../@modules/platform/services/platform.service';

@Component({
  selector: 'app-logo-slider',
  templateUrl: './logo-slider.component.html',
})
export class LogoSliderComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>;
  slider: KeenSliderInstance;


  @Input() reverse: boolean = false;
  @Input() onDark: boolean = false;
  @Input() priority: boolean = false;

  @Input() slidesPerViewDesktop: number = 6;
  @Input() slidesPerViewMobile: number = 3;

  brandLogos: BrandLogo[];

  constructor(
    protected platformService: PlatformService, ) {
  }

  ngOnInit(): void {
    if (this.reverse) {
      this.brandLogos = BRAND_LOGOS.slice().reverse();
    } else {
      this.brandLogos = BRAND_LOGOS;
    }
  }

  ngAfterViewInit(): void {
    if (!this.platformService.isBrowser()) {
      return;
    }

    const animation = {duration: 4000, easing: (t) => t};

    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      loop: true,
      renderMode: 'performance',
      drag: false,
      slides: { perView: this.slidesPerViewMobile },
      breakpoints: {
        '(min-width: 768px)': {
          slides: { perView: this.slidesPerViewDesktop },
        },
      },
      created: (slider) => {
        slider.moveToIdx(
          this.reverse ? -1 : 1,
          true,
          animation
        );
      },
      updated: (slider) => {
        slider.moveToIdx(
          this.reverse ? slider.track.details.abs - 1 : slider.track.details.abs + 1,
          true,
          animation);
      },
      animationEnded: (slider) => {
        slider.moveToIdx(
          this.reverse ? slider.track.details.abs - 1 : slider.track.details.abs + 1,
          true,
          animation);
      },
    });
  }

  ngOnDestroy(): void {
    if (this.slider) {
      this.slider.destroy();
    }
  }
}
