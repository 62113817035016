import { Component, Input } from '@angular/core';
import { Project } from '../../models/project.model';

@Component({
  selector: 'app-project-graphics',
  templateUrl: './project-graphics.component.html'
})
export class ProjectGraphicsComponent {
  @Input() project: Project;
}
