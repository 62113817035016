<div class="w-full flex flex-col">
  <h2 *ngIf="contentModule.title" class="font-abril-bold text-48 text-scale-80 text-[#F9626D] leading-111">{{ contentModule.title }}</h2>
  <span *ngIf="contentModule.description" class="font-300 text-22 leading-137 text-scale-42 mt-2 text-black">
    {{ contentModule.description }}
  </span>
  <div class="w-full mt-8 sm:mt-scale-h-18 bg-gray-300 rounded-3 sm:rounded-12 h-56 sm:h-[70vh] overflow-hidden relative">
    <picture *ngIf="contentModule.imageDesktop && contentModule.imageMobile">
      <source media="(min-width:768px)" [srcset]="contentModule.imageDesktop">
      <source media="(max-width:768px)" [srcset]="contentModule.imageMobile">
      <img [src]="contentModule.imageDesktop" alt="Fleka" class="absolute inset-0 object-cover object-center w-full h-full">
    </picture>
  </div>
</div>
