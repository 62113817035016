<div class="w-full my-10">
  <div class="container flex flex-col">

    <div class="flex flex-col space-y-8 py-8">
      <app-module-a010
        [contentModule]="contentModules[0]"
      ></app-module-a010>
      <pre
        class="flex p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="hover:cursor-pointer text-12 text-black leading-125 w-full"
              (click)="copyToClipboard(moduleA010)">{{ moduleA010 }}</code>
      </pre>
    </div>

    <div class="flex flex-col space-y-8 py-8">
      <app-module-a020
        [contentModule]="contentModules[1]"
      ></app-module-a020>
      <pre
        class="flex p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="hover:cursor-pointer text-12 text-black leading-125 w-full"
              (click)="copyToClipboard(moduleA020)">{{ moduleA020 }}</code>
      </pre>
    </div>

    <div class="flex flex-col space-y-8 py-8">
      <app-module-a030
        [contentModule]="contentModules[2]"
      ></app-module-a030>
      <pre
        class="flex p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="hover:cursor-pointer text-12 text-black leading-125 w-full"
              (click)="copyToClipboard(moduleA030)">{{ moduleA030 }}</code>
      </pre>
    </div>

    <div class="flex flex-col space-y-8 py-8">
      <app-module-a040
        [contentModule]="contentModules[3]"
      ></app-module-a040>
      <pre
        class="flex p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="hover:cursor-pointer text-12 text-black leading-125 w-full"
              (click)="copyToClipboard(moduleA040)">{{ moduleA040 }}</code>
      </pre>
    </div>

    <div class="flex flex-col space-y-8 py-8">
      <app-module-a050
        [contentModule]="contentModules[4]">
      </app-module-a050>
      <pre
        class="flex p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="hover:cursor-pointer text-12 text-black leading-125 w-full"
              (click)="copyToClipboard(moduleA050)">{{ moduleA050 }}</code>
      </pre>
    </div>

    <div class="flex flex-col space-y-8 py-8">
      <app-module-a060 [contentModule]="contentModules[5]"></app-module-a060>
      <pre
        class="flex p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="hover:cursor-pointer text-12 text-black leading-125 w-full"
              (click)="copyToClipboard(moduleA060)">{{ moduleA060 }}</code>
      </pre>
    </div>

    <div class="flex flex-col space-y-8 py-8">
      <app-module-a070 [contentModule]="contentModules[6]"></app-module-a070>
      <pre
        class="flex p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="hover:cursor-pointer text-12 text-black leading-125 w-full"
              (click)="copyToClipboard(moduleA070)">{{ moduleA070 }}</code>
      </pre>
    </div>

    <div class="flex flex-col space-y-8 py-8">
      <app-module-a080
        [contentModule]="contentModules[7]"
      ></app-module-a080>
      <pre
        class="flex p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="hover:cursor-pointer text-12 text-black leading-125 w-full"
              (click)="copyToClipboard(moduleA080)">{{ moduleA080 }}</code>
      </pre>
    </div>

    <div class="flex flex-col space-y-8 py-8">
      <app-module-a090
        [contentModule]="contentModules[8]"
      ></app-module-a090>
      <pre
        class="flex p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="hover:cursor-pointer text-12 text-black leading-125 w-full"
              (click)="copyToClipboard(moduleA090)">{{ moduleA090 }}</code>
      </pre>
    </div>

  </div>


  <div class="flex flex-col space-y-8 py-8">
    <app-module-a100
     [contentModule]="contentModules[9]"
    ></app-module-a100>
    <div class="container">
      <pre
        class="flex p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="hover:cursor-pointer text-12 text-black leading-125 w-full"
                (click)="copyToClipboard(moduleA100)">{{ moduleA100 }}</code>
      </pre>
    </div>
  </div>

  <div class="container flex flex-col">
    <div class="flex flex-col space-y-8 py-8">
      <app-module-a110
        [contentModule]="contentModules[10]"
      ></app-module-a110>
      <pre
        class="flex p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="hover:cursor-pointer text-12 text-black leading-125 w-full"
              (click)="copyToClipboard(moduleA110)">{{ moduleA110 }}</code>
      </pre>
    </div>

    <div class="flex flex-col space-y-8 py-8">
      <app-module-a120
        [contentModule]="contentModules[11]"
      ></app-module-a120>
      <pre
        class="flex p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="hover:cursor-pointer text-12 text-black leading-125 w-full"
              (click)="copyToClipboard(moduleA120)">{{ moduleA120 }}</code>
      </pre>
    </div>

    <div class="flex flex-col space-y-8 py-8">
      <app-module-a130
       [contentModule]="contentModules[12]"
      ></app-module-a130>
      <pre
        class="flex p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
        <code class="hover:cursor-pointer text-12 text-black leading-125 w-full"
              (click)="copyToClipboard(moduleA130)">{{ moduleA130 }}</code>
      </pre>
    </div>

  </div>

  <div class="flex flex-col space-y-8 py-8">
    <app-module-a140
      [contentModule]="contentModules[13]"
    ></app-module-a140>
    <div class="container">
      <pre
        class="flex p-3 bg-black bg-opacity-20 border border-s-400 border-opacity-10 inline-flex text-14 rounded w-full overflow-auto">
          <code class="hover:cursor-pointer text-12 text-black leading-125 w-full"
                (click)="copyToClipboard(moduleA140)">{{ moduleA140 }}</code>
      </pre>
    </div>

  </div>

</div>

