<div class="container py-10 sm:py-scale-h-27">
  <div class="w-full grid grid-cols-1 sm:grid-cols-2 gap-y-10 sm:gap-y-scale-h-8 sm:gap-x-scale-w-44">
    <div *ngFor="let project of projects, let even = even"
         [ngClass]="{'sm:pb-scale-h-19': even, 'sm:pt-scale-h-19': !even}">
      <app-work-project-card [project]="project"></app-work-project-card>
    </div>
  </div>
  <div class="pt-8 sm:pt-scale-h-12 w-full flex justify-center">
    <a class="btn btn-primary group"
       href="https://works.fleka.me/"
       title="Works - Fleka"
       target="_blank">
      <span class="btn-body">Other works</span>
      <span
        class="btn-arrow-wrapper">
        <fa-icon class="btn-arrow-icon"
          [icon]="faArrowRight">
        </fa-icon>
      </span>
    </a>
  </div>
</div>
