<div id="#header" class="fixed top-0 bg-transparent w-full py-4 sm:py-6 z-100 font-inter pointer-events-none">
  <div *ngIf="!menuVisible" class="container">
    <div class="w-full flex justify-between items-start relative"
         [ngClass]="menuShrinked ? 'sm:items-center' :  'sm:items-start'">
      <div>
        <button (click)="onLogoClick()" class="pointer-events-auto">
          <img ngSrc="assets/img/logos/fleka_logo.svg"
               width="258"
               height="136"
               alt="Fleka"
               [ngClass]="logoHidden ? 'top-[-200px]' : 'top-0'"
               class="absolute left-0 sm:relative min-w-[91px] max-w-[136px] w-[91px] sm:w-scale-w-32 transition-[top] duration-800">
        </button>
      </div>

      <button class="flex sm:hidden text-16 leading-120 font-500 py-2 px-4 rounded-11 pointer-events-auto bg-gray-100"
              (click)="toggleMenuDialog()">Menu
      </button>

      <div
          [ngClass]="!menuShrinked ? 'top-0': 'top-[-220px]'"
          class="absolute right-0 transition-[top] duration-500 hidden sm:flex flex-col items-end gap-y-3 sm:gap-y-scale-h-3 pointer-events-auto">
        <div *ngFor="let menuItem of menuItems" class="group relative font-inter font-300 text-black text-scale-20 leading-100 text-current"
           [ngClass]="{'hidden': menuItem.url === '/', 'text-black': !onDark, 'text-white': onDark}">
          <app-menu-item [menuItem]="menuItem"></app-menu-item>
          <app-animated-underline [origin]="'right'"></app-animated-underline>
        </div>
      </div>

      <div [ngClass]="menuShrinked ? 'top-0': 'top-[-220px]'"
           class="absolute right-0 transition-[top] duration-500 hidden sm:flex flex-row space-x-4 pointer-events-auto">
        <a [routerLink]="['contact']" class="btn btn-primary btn-body text-scale-18">Let's Talk</a>
        <button class="btn btn-primary btn-body text-scale-18" (click)="toggleMenuDialog()">Menu</button>
      </div>
    </div>
  </div>
  <div [ngClass]="{'-top-full' : !menuVisible, 'top-0': menuVisible}"
       class="fixed bg-white w-full py-3 sm:py-8 h-screen sm:h-auto sm:max-h-screen transition-[top] duration-1000 pointer-events-auto overflow-y-auto">
    <app-menu (closeMenu)="toggleMenuDialog()"></app-menu>
  </div>
</div>


<!--backdrop-->
<div class="hidden sm:block absolute inset-0 bg-black z-10 transition-opacity duration-500"
     [ngClass]="{'opacity-60 pointer-events-auto': menuVisible, 'opacity-0 pointer-events-none': !menuVisible}"
   (click)="closeMenu()">
</div>
