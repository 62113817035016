import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { AboutLayoutComponent } from './components/about-layout/about-layout.component';
import { AboutHeroComponent } from './components/about-hero/about-hero.component';
import { AboutRoutingModule } from './about-routing.module';
import { SharedModule } from '../../@shared/shared.module';
import { AboutTeamComponent } from './components/about-team/about-team.component';
import { AboutEmployeeListComponent } from './components/about-employee-list/about-employee-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AboutJobsComponent } from './components/about-jobs/about-jobs.component';
import { RouterModule } from '@angular/router';
import { AnimatedUnderlineComponent } from '../../@shared/components/animated-underline/animated-underline.component';

@NgModule({
  declarations: [
    AboutLayoutComponent,
    AboutHeroComponent,
    AboutTeamComponent,
    AboutJobsComponent,
    AboutEmployeeListComponent
  ],
    imports: [
        CommonModule,
        AboutRoutingModule,
        SharedModule,
        FontAwesomeModule,
        RouterModule,
        NgOptimizedImage,
        AnimatedUnderlineComponent
    ]
})
export class AboutModule {
}
