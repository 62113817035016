<div #imagesCarousel class="container flex overflow-x-scroll pointer-events-none hide-scrollbar">
  <div
    class="images-wrapper flex items-end space-x-3 sm:space-x-scale-h-6 pt-14 sm:pt-scale-h-27 pb-13 sm:pb-scale-h-18">
    <div *ngFor="let imagePath of imagePaths"
         class="relative carousel-image-width aspect-square rounded-4 overflow-hidden">
      <img [ngSrc]="imagePath"
           alt="Fleka"
           fill="true">
    </div>
  </div>
</div>
