import { Component } from '@angular/core';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-homepage-product-sken',
  templateUrl: './homepage-product-sken.component.html'
})
export class HomepageProductSkenComponent {
  faArrowRight = faArrowRight;
}
