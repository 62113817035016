import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../Library/services/global.service';
import { BehaviorSubject } from 'rxjs';
import { SeoService } from '../../../Library/services/seo/seo.service';
import { ParallaxService } from '../../../Library/services/parallax.service';
import { MutationService } from '../../../Library/services/mutation.service';
import { SmoothScrollService } from '../../../Library/services/smooth-scroll.service';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppLayoutComponent implements OnInit, AfterViewInit {
  public is404Subject: BehaviorSubject<boolean>;

  constructor(
    private seoService: SeoService,
    globalService: GlobalService,
    private parallaxService: ParallaxService,
    private mutationService: MutationService,
    private smoothScrollService: SmoothScrollService
  ) {
    this.is404Subject = globalService.is404;
  }

  ngOnInit(): void {
    this.is404Subject.subscribe(is404 => {
      if (is404) {
        this.seoService.refreshWithDefault();
      }
    });
  }

  ngAfterViewInit(): void {
    this.parallaxService.init();
    this.smoothScrollService.init();
    this.mutationService.createMutationObserver();
  }

}
