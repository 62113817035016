import { Inject, Injectable } from '@angular/core';
import { SeoItem } from './seo-item.model';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SeoCanonicalService {

  constructor(
    @Inject(DOCUMENT) private document: any
  ) {
  }

  reset(): void {
    const canonical = this.document.querySelector('link[rel="canonical"]');
    if (canonical) {
      canonical.setAttribute('href', '');
    }
  }

  refresh(seoItem: SeoItem): void {
    let canonical = this.document.querySelector('link[rel="canonical"]');

    if (!canonical) {
      canonical = this.document.createElement('link')
      canonical.setAttribute('rel', 'canonical');
    }

    canonical.setAttribute('href', seoItem.canonical_url);
    this.document.head.appendChild(canonical);
  }
}
