import {Component, Input, AfterViewInit, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-module-a060',
  templateUrl: './module-a060.component.html'
})
export class ModuleA060Component implements AfterViewInit {

  @Input() contentModule: any;

  constructor(@Inject(PLATFORM_ID) private platformId) {
  }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    // const swiper = new Swiper('.module-a060-swiper', {
    //   slidesPerView: 1.25,
    //   spaceBetween: 16,
    //   breakpoints: {
    //     1140: {
    //       slidesPerView: 2,
    //       spaceBetween: 20
    //     }
    //   }
    // });
  }

}
