import { Component } from '@angular/core';
import { SmoothScrollService } from '../../../../Library/services/smooth-scroll.service';

@Component({
  selector: 'app-homepage-services',
  templateUrl: './homepage-services.component.html',
  styleUrls: ['./homepage-services.component.scss']
})
export class HomepageServicesComponent {
  services: {title: string, description: string}[] = [
    {
      title: 'Product discovery and conceptualization',
      description: 'At fleka, we specialize in transforming your initial ideas into viable digital solutions. Whether you’re in the ideation phase or transforming existing products, оur approach includes comprehensive business analysis, detailed market research, and a focus on user experience to ensure your product resonates with your target audience. We emphasize customer journey mapping to tailor our services to your unique needs, guiding you through each step of digital transformation.'
    },
    {
      title: 'User experience and Interface design',
      description: 'Our approach to user experience (UX) and interface design is rooted in a deep understanding of user needs. We recognize the critical role of UX in determining the success of your digital products. By employing cutting-edge design tools and user-focused methodologies, we craft interfaces that are not only visually appealing but also highly functional and intuitive. Our designs prioritize user satisfaction, loyalty, and engagement, which are key drivers of increased business revenue. By addressing user pain points and focusing on usability, we ensure your digital product stands out in a crowded digital landscape.'
    },
    {
      title: 'Web and Mobile software development',
      description: `In our Web and Mobile Software Development services at fleka, we go beyond mere functionality to deliver excellence. Our extensive experience across diverse sectors like retail, publishing, banking, e-commerce, and government equips us with a unique perspective and broad expertise. We specialize in creating custom solutions that are not just scalable but also future-proof, ensuring they evolve with your business. Our commitment to technical excellence and client-focused approach means we deliver software that not only meets but exceeds expectations.`
    },
    {
      title: 'Digital strategy and Product development',
      description: 'In our Digital Strategy and Product Development services at fleka, we guide you from concept to execution. We leverage our extensive industry knowledge and technological skills to streamline your processes, cut costs, and expand your customer base. Our hands-on support ensures that your journey in the digital world is not just successful but transformative. Partner with us to elevate your digital strategy through our all-encompassing consulting services!'
    }
  ]

  indexOfOpenedService: number = 0;
  transitionDurationInMilliseconds: number = 600;

  constructor(private smoothScrollService: SmoothScrollService) {
  }

  toggleService(index: number): void {
    this.indexOfOpenedService = index === this.indexOfOpenedService ? -1 : index;

    setTimeout(() => {
      this.smoothScrollService.resizeScrollingContext();
    }, this.transitionDurationInMilliseconds);

  }
}
