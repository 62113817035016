import {Injectable} from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {SeoItem} from './seo-item.model';

@Injectable({
  providedIn: 'root'
})
export class SeoTwitterService {

  constructor(
    private metaService: Meta
  ) {
  }

  reset(): void {
    this.metaService.removeTag('name="twitter:card"');
    this.metaService.removeTag('name="twitter:site"');
    this.metaService.removeTag('name="twitter:title"');
    this.metaService.removeTag('name="twitter:description"');
    // this.metaService.removeTag('name="twitter:image"');
  }

  refresh(seoItem: SeoItem): void {

    const title = seoItem.seo_tw_title ? seoItem.seo_tw_title : seoItem.getTitle();
    const description = seoItem.seo_tw_description ? seoItem.seo_tw_description : seoItem.getDescription();

    this.metaService.updateTag({ name: 'twitter:card', content: seoItem.seo_tw_card });
    this.metaService.updateTag({ name: 'twitter:title', content: title });
    this.metaService.updateTag({ name: 'twitter:description', content: description });

    if (seoItem.seo_tw_site) {
      this.metaService.updateTag({ name: 'twitter:site', content: seoItem.seo_tw_site });
    }

    if (seoItem.seo_tw_image_url) {
      this.metaService.updateTag({ name: 'twitter:image', content: seoItem.seo_tw_image_url });
    }
  }
}
