import { NgModule, Injectable } from '@angular/core';
import { RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { MediaService } from './services/media.service';
import { Media } from './models/media.model';
import { catchError } from 'rxjs/operators';
import { GlobalService } from '../services/global.service';

@Injectable()
export class ItemResolver  {
  constructor(private service: MediaService, private globalService: GlobalService) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<Media> | Promise<Media> | any {
    return this.service.get(route.paramMap.get('id')).pipe(
      catchError(error => {
        this.globalService.is404.next(true);
        return of(error);
      }));
  }
}

const routes = [];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
})
export class MediaRoutingModule {
}
