import { Injectable } from '@angular/core';
import { PlatformService } from '../../@modules/platform/services/platform.service';

class CustomLetterSpacing {
  char1: string;
  char2: string;
  spacing_desktop: string;
  spacing_mobile: string;
}

@Injectable({
  providedIn: 'root'
})
export class TypographyService {

  private InterFontFamily_customLetterSpacing: CustomLetterSpacing[] = [{
    char1: 'c',
    char2: 'y',
    spacing_desktop: 'max(-0.42vw, -8px)',
    spacing_mobile: '-3px'
  }];

  private intersectionObserver: IntersectionObserver;

  constructor(private platformService: PlatformService) {
  }

  public init(): void {
    if (!this.platformService.isBrowser()) {
      return;
    }

    this.createIntersectionObserver();

    const textElements = document.querySelectorAll('.animated-text, .animated-text--on-dark');
    textElements.forEach((elem: Element) => {
      this.transformText(elem);

      this.intersectionObserver.observe(elem);
    });
  }

  private transformText(textElement: Element): void {
    const textRows = textElement.querySelectorAll('.animated-text-row');

    textRows.forEach((row: Element, rowIndex: number) => {
      row.insertAdjacentHTML('beforeend', '&nbsp;');
      this.setAnimationDelayOfCharacters(row, rowIndex);
    });
  }

  private setAnimationDelayOfCharacters(row: Element, rowIndex: number): void {
    const rowText = row.textContent;
    row.textContent = '';

    for (let charIndex = 0; charIndex < rowText.length; charIndex++) {
      const span: HTMLSpanElement = document.createElement('span');
      span.textContent = rowText.charAt(charIndex);

      span.style.letterSpacing = this.calculateLetterSpacing(rowText.charAt(charIndex), rowText.charAt(charIndex + 1));
      span.style.animationDelay = this.calculateAnimationDelay(rowIndex, charIndex);

      row.appendChild(span);
    }
  }

  private calculateAnimationDelay(rowIndex: number, charIndex: number): string {
    const initialDelay = 500;
    const delayBetweenRows = 400;
    const delayBetweenChars = 55;
    const randomDelayRange = 35;

    return initialDelay
      + delayBetweenRows * rowIndex
      + delayBetweenChars * charIndex
      + randomDelayRange * Math.random()
      + 'ms';
  }

  private calculateLetterSpacing(currentChar: string, nextChar: string): string {
    currentChar = currentChar.toLowerCase();
    nextChar = nextChar.toLowerCase();

    let spacing: string;

    this.InterFontFamily_customLetterSpacing.some(instance => {
      if (currentChar === instance.char1 && nextChar === instance.char2) {
        spacing = this.platformService.isMobile() ? instance.spacing_mobile : instance.spacing_desktop;
        return true;
      }
      return false;
    });

    return spacing ?? '0px';
  }

  private createIntersectionObserver(): void {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.75
    };

    this.intersectionObserver = new IntersectionObserver(this.handleIntersect, options);
  }

  private handleIntersect(animatedTextElements): void {
    animatedTextElements.forEach((entry) => {
      if (entry.isIntersecting) {
        if (entry.target.classList.contains('animated-text--on-dark')) {
          entry.target.classList.add('init-title-animation--on-dark');
        } else {
          entry.target.classList.add('init-title-animation');
        }
      }
    });
  }
}
