import { Injectable } from '@angular/core';
import { MenuItem } from '../models/menu-item.model';

@Injectable({
  providedIn: 'root'
})

export class MenuService {

  menuItems: MenuItem[] = [
    new MenuItem({
      title: 'Home',
      url: '/',
    }),
    new MenuItem({
      title: 'Work',
      url: '/work',
    }),
    new MenuItem({
      title: 'About',
      url: '/about'
    }),
    new MenuItem({
      title: 'Careers',
      url: '/careers'
    }),
    new MenuItem({
      title: 'Stories',
      url: 'https://blog.fleka.me/',
      external: true,
    }),
    new MenuItem({
      title: 'Contact',
      url: '/contact'
    }),
  ];

  getMenuItems(): any {
    return this.menuItems;
  }
}
