<div class="parallax-wrapper">

  <div class="parallax-element bg-gray-100">
    <app-contact-hero></app-contact-hero>
  </div>

  <div class="parallax-element bg-black">
    <app-footer [colors_inverted]="true"></app-footer>
  </div>

</div>
