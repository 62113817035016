import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HomepageLayoutComponent } from './components/homepage-layout/homepage-layout.component';
import { RouteName } from '../../Library/routes';

const routes = [
  {
    path: '',
    component: HomepageLayoutComponent,
    data: {
      routeName: RouteName.HOME
    }
  }

];
@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ]
})
export class HomepageRoutingModule { }
