<div class="container grid grid-cols-1 sm:grid-cols-2 overflow-y-auto">
  <div class="grid grid-cols-1 w-full content-between h-full order-2 sm:order-1">
    <div
      class="w-full h-full sm:w-14/24 grid grid-cols-2 justify-items-start content-start gap-y-10 sm:gap-y-scale-h-10 gap-x-4">
      <div class="w-full">
        <span class="text-gray-300 text-18 text-scale-20 font-400 leading-120">Contact</span>
      </div>
      <div class="text-18 text-scale-20 flex flex-col gap-4 sm:gap-0 font-300">
        <div class="flex flex-col sm:flex-row items-start sm:gap-1">
          <span>Phone:</span>
          <a [href]="contactDetails['tel'].href" class="group relative">
            {{ contactDetails['tel'].label }}
            <app-animated-underline></app-animated-underline>
          </a>
        </div>
        <div class="flex flex-col sm:flex-row items-start sm:gap-1">
          <span>Email: </span>
          <button *ngIf="!emailVisible" (click)="showEmail()" class="group relative inline p-0">
            Show
            <app-animated-underline [defaultVisible]="true"></app-animated-underline>
          </button>
          <a *ngIf="emailVisible" [href]="contactDetails['email'].href" class="group relative">
            {{ contactDetails['email'].label }}
            <app-animated-underline></app-animated-underline>
          </a>
        </div>
      </div>
      <div class="w-full">
        <span class="text-gray-300 text-18 text-scale-20 font-400 leading-120">Social Media</span>
      </div>
      <div class="w-full flex flex-col items-start font-300 text-18 text-scale-20 leading-140">
        <div class="group relative">
          <a [href]="contactDetails['instagram'].href" target="_blank">
            {{ contactDetails['instagram'].label }}
          </a>
          <app-animated-underline></app-animated-underline>
        </div>
        <div class="group relative">
          <a [href]="contactDetails['facebook'].href" target="_blank">
            {{ contactDetails['facebook'].label }}
          </a>
          <app-animated-underline></app-animated-underline>
        </div>
        <div class="group relative">
          <a [href]="contactDetails['linkedin'].href" target="_blank">
            {{ contactDetails['linkedin'].label }}
          </a>
          <app-animated-underline></app-animated-underline>
        </div>
      </div>
    </div>
    <div class="w-full sm:w-14/24 grid grid-cols-2 gap-y-10 sm:gap-y-scale-h-10 gap-x-4 mt-10 sm:mt-0">
      <div class="w-full">
        <span class="text-gray-300 text-18 text-scale-20 font-400">Address</span>
      </div>
      <a [href]="contactDetails['address'].href" target="_blank"
         class="w-full group flex flex-col items-start font-300 text-18 text-scale-20 leading-140">
        <span class="relative">
          {{ contactDetails['address'].label[0] }}
          <app-animated-underline></app-animated-underline>
        </span>
        <span class="relative">
          {{ contactDetails['address'].label[1] }}
          <app-animated-underline [delay]="100"></app-animated-underline>
        </span>
        <span class="relative">
          {{ contactDetails['address'].label[2] }}
          <app-animated-underline [delay]="200"></app-animated-underline>
        </span>
      </a>
    </div>
  </div>
  <div class="w-full flex justify-end items-start order-1 sm:order-2 sm:pb-60">
    <div class="w-full sm:w-14/24 self-end flex justify-between items-start font-abril-bold ">
      <div
        class="flex flex-col items-start gap-y-5 sm:gap-y-scale-h-5 text-44 text-scale-76 mt-17 mb-28 sm:mb-0 sm:mt-0">
        <div *ngFor="let menuItem of menuItems" class="group relative text-44 text-scale-76 leading-100">
          <app-menu-item [menuItem]="menuItem"></app-menu-item>
          <app-animated-underline [thickness]="4"></app-animated-underline>
        </div>
      </div>
    </div>

    <button
      (click)="exitMenu()"
      class="bg-gray-100 flex px-4 py-3 font-inter text-gray-black text-16 leading-120 rounded-11">
      Close
    </button>
  </div>
</div>
