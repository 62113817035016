<div class="flex flex-col sm:flex-row">
  <div class="w-full sm:w-6/24 flex items-start justify-start">
    <span *ngIf="contentModule.order" class="font-300 font-abril-light text-40 text-scale-72">
      {{ contentModule.order | number:'2.0-0' }}.
    </span>
  </div>
  <div class="w-full sm:w-18/24 mt-2">
    <h2 *ngIf="contentModule.title" class="font-abril-bold text-30 text-scale-72 leading-111">
      {{ contentModule.title }}
    </h2>
    <p *ngIf="contentModule.description"
         class="mt-6 sm:mt-scale-h-8 font-300 text-16 text-scale-40 leading-155"
         [innerHtml]="contentModule.description">
    </p>
  </div>
</div>
