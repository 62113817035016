import { environment } from 'src/environments/environment';
import { RouteName } from '../routes';
import { SeoItem } from '../services/seo/seo-item.model';


export const seoRulesNotFound = {
  en: new SeoItem({
    title: "Page not found | Fleka",
    seo_title: "Page not found | Fleka",
    seo_fb_title: "Page not found | Fleka",
    seo_fb_description: "Sorry, we can’t find the page that you’re looking for because it does not exist or it was removed.",
    seo_fb_image_url: environment.websiteUrl + "/assets/img/seo/seo_1200x630.jpg",
    seo_description: "Sorry, we can’t find the page that you’re looking for because it does not exist or it was removed.",
    seo_tw_title: "Page not found | Fleka",
    seo_tw_description: "Sorry, we can’t find the page that you’re looking for because it does not exist or it was removed.",
    seo_tw_image_url: environment.websiteUrl + "/assets/img/seo/seo_1200x675.jpg",
    seo_tw_card: "summary_large_image",
    seo_tw_site: "@fleka",
    seo_fb_type: 'website',
    seo_keywords: '404,not,found',
    description: "Sorry, we can’t find the page that you’re looking for because it does not exist or it was removed.",
    canonical_url: environment.websiteUrl,
    route_name: null,
    robots: '',
    url: environment.websiteUrl,
  }),
};
