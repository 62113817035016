<div class="w-full pt-10 pb-8 sm:pt-scale-h-20 sm:pb-scale-h-24 border-b border-gray-300">
  <div class="container">
    <h3 class="font-abril-bold text-black text-30 text-scale-68 leading-111">
      Our clients
    </h3>
  </div>
</div>
<div class="pt-9 sm:pt-scale-h-14 pb-18 sm:pb-scale-h-36">
  <app-logo-slider></app-logo-slider>
  <div class="pt-4 sm:pt-scale-h-10"></div>
  <app-logo-slider [reverse]="true"></app-logo-slider>
</div>
