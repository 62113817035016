import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DesignSystemComponent } from './components/design-system/design-system.component';
import { DsButtonsComponent } from './components/ds-buttons/ds-buttons.component';
import { DesignSystemRoutingModule } from './design-system-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DsModulesComponent } from './components/ds-modules/ds-modules.component';
import { ModuleA010Component } from './components/module-a010/module-a010.component';
import { ModuleA020Component } from './components/module-a020/module-a020.component';
import { ModuleA030Component } from './components/module-a030/module-a030.component';
import { ModuleA040Component } from './components/module-a040/module-a040.component';
import { ModuleA050Component } from './components/module-a050/module-a050.component';
import { ModuleA060Component } from './components/module-a060/module-a060.component';
import { ModuleA070Component } from './components/module-a070/module-a070.component';
import { ModuleA080Component } from './components/module-a080/module-a080.component';
import { ModuleA090Component } from './components/module-a090/module-a090.component';
import { ModuleA100Component } from './components/module-a100/module-a100.component';
import { ModuleA110Component } from './components/module-a110/module-a110.component';
import { ModuleA120Component } from './components/module-a120/module-a120.component';
import { ModuleA130Component } from './components/module-a130/module-a130.component';
import { ModuleA140Component } from './components/module-a140/module-a140.component';
import { DsScalableSizesComponent } from './components/ds-scalable-sizes/ds-scalable-sizes.component';


@NgModule({
    declarations: [
        DesignSystemComponent,
        DsButtonsComponent,
        DsModulesComponent,
        ModuleA010Component,
        ModuleA020Component,
        ModuleA030Component,
        ModuleA040Component,
        ModuleA050Component,
        ModuleA060Component,
        ModuleA070Component,
        ModuleA080Component,
        ModuleA090Component,
        ModuleA100Component,
        ModuleA110Component,
        ModuleA120Component,
        ModuleA130Component,
        ModuleA140Component,
        DsScalableSizesComponent
    ],
  exports: [
    ModuleA010Component,
    ModuleA020Component,
    ModuleA050Component,
    ModuleA030Component,
    ModuleA070Component,
    ModuleA040Component,
    ModuleA060Component,
    ModuleA100Component,
    ModuleA080Component,
    ModuleA120Component
  ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        DesignSystemRoutingModule,
        ReactiveFormsModule,
        NgOptimizedImage
    ]
})
export class DesignSystemModule {
}
